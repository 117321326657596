import { Button, Text } from "../../components";
import Localization from "../../localization";
import React from "react";

const Actions = ({ activated = false, onActivate = () => {} }) => {
  return (
    <div className="p-20-h">
      {!activated ? (
        <Button
          type="primary"
          className="w-100"
          onClick={onActivate}
          children={Localization.general.activate}
        />
      ) : (
        <React.Fragment>
          <Text
            align="start"
            level={5}
            type="destructive"
            children={Localization.general.oops}
          />
          <Text
            align="start"
            type="destructive"
            children={Localization.activate.footer_desc}
          />
        </React.Fragment>
      )}
    </div>
  );
};

export default Actions;
