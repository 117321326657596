const initials = (name = "") =>
  name
    .split(" ")
    .map((_) => _[0])
    .join("");

const storage = localStorage;

const sum = (data = []) => data.reduce((a, b) => a + b, 0);

const isEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const isJSON = (str) => {
  let value = typeof str !== "string" ? JSON.stringify(str) : str;
  try {
    value = JSON.parse(value);
  } catch (e) {
    return false;
  }

  return typeof value === "object" && value !== null;
};

const toCurrency = (amount) => `${amount} Kr`;

const maskLicenseKey = (value) =>
  !value
    ? ""
    : value
        .match(/.{1,4}/g)
        .join(" ")
        .toUpperCase();

const Tools = {
  sum,
  isJSON,
  storage,
  isEmail,
  initials,
  toCurrency,
  maskLicenseKey,
};

export default Tools;
