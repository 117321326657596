import React, { useCallback, useImperativeHandle, useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { Modal as AntModal, Card } from "antd";
import Text from "../text";

const Modal = (
  {
    title = "",
    child = () => null,
    cardStyles = {},
    classNames = {},
    style,
    cardId = "",
    closeable = true,
    ...props
  },
  ref
) => {
  const [visible, setVisible] = useState(false);
  const [config, setConfig] = useState();
  const [data, setData] = useState({});

  useImperativeHandle(
    ref,
    () => ({
      close,
      open: (data, config) => {
        if (!!data) setData(data);
        if (!!config) setConfig(config);
        setVisible(true);
      },
    }),
    []
  );

  const close = useCallback(() => {
    setVisible(false);
  }, []);

  return (
    <AntModal
      open={visible}
      destroyOnClose={true}
      onCancel={() => closeable && close()}
      style={{ pointerEvents: "auto", top: "2em", ...style }}
      modalRender={() => (
        <Card
          id={cardId}
          classNames={classNames}
          children={child(data)}
          title={
            (!!title || !!config?.title) && (
              <Text children={config?.title ?? title} level={5} />
            )
          }
          styles={{
            body: {
              maxHeight: "85vh",
              overflow: "scroll",
              ...(cardStyles.body ?? {}),
            },
            ...cardStyles,
          }}
          extra={
            closeable && (
              <Text
                level={4}
                onClick={close}
                className="cursor-pointer"
                children={<CloseOutlined size={50} />}
              />
            )
          }
        />
      )}
      {...props}
    />
  );
};

export default React.forwardRef(Modal);
