import { AuthProvider, GeneralProvider } from "./provider";
import { Elements } from "@stripe/react-stripe-js";
import { ThemeProvider } from "styled-components";
import { BrowserRouter } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import Localization from "./localization";
import React, { useEffect } from "react";
import { LightTheme } from "./themes";
import { ConfigProvider } from "antd";
import { App as AntApp } from "antd";
import { Constants } from "./utils";
import { Header } from "./layouts";
import Router from "./router";

const stripePromise = loadStripe(Constants.Project.stripeApiKey);

function App() {
  useEffect(() => {
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", Localization.htmlHeader.desc);
  }, []);

  return (
    <Elements
      stripe={stripePromise}
      options={{ locale: Localization.getLanguage() }}
    >
      <BrowserRouter>
        <div id="app">
          <GeneralProvider>
            <AuthProvider>
              <ConfigProvider theme={LightTheme}>
                <AntApp>
                  <ThemeProvider theme={LightTheme}>
                    <Header />
                    <Router />
                  </ThemeProvider>
                </AntApp>
              </ConfigProvider>
            </AuthProvider>
          </GeneralProvider>
        </div>
      </BrowserRouter>
    </Elements>
  );
}

export default App;
