import { Card, Col, Flex, Row, Segmented } from "antd";
import React, { useEffect, useState } from "react";
import { Button, Text } from "../../components";
import { StarFilled } from "@ant-design/icons";
import Localization from "../../localization";
import { ServicesApi } from "../../apis";
import styled from "styled-components";
import { Tools } from "../../utils";

const StyledPlanCard = styled(Card)`
  cursor: pointer;
  transition: 0.3s ease;
  &:hover {
    border: ${(_) => `1px solid ${_.theme.token.colorPrimary}`};
  }
`;

const PaymentPlanLayout = ({ onSubscribe = () => {} }) => {
  const [name, setName] = useState("yearly");
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    ServicesApi.paymentPlans().then(setPlans);
  }, []);

  return (
    <div>
      <Text level={5} children={Localization.services.plan_modal_subtitle} />
      <Text>
        {Localization.services.plan_modal_dec + " "}
        <Text
          strong
          underline
          level="link"
          target="_blank"
          href="https://www.safe.land/butik/connect-plus/"
          children={Localization.general.read_more}
        />
      </Text>
      <Row className="m-12-v">
        <Col xs={0} sm={0} md={0} lg={4} />
        <Col xs={24} sm={24} md={24} lg={16}>
          <Segmented
            block
            value={name}
            defaultValue="center"
            onChange={(value) => setName(value)}
            options={[
              { label: Localization.general.monthly, value: "monthly" },
              {
                value: "yearly",
                label: `${Localization.general.annually} (${
                  Localization.general.save
                } ${16}%)`,
              },
            ]}
          />
        </Col>
        <Col xs={0} sm={0} md={0} lg={4} />
      </Row>
      <Row
        gutter={[16, 16]}
        justify="space-between"
        children={React.Children.toArray(
          plans.map((plan) => {
            const { title, amount, infos, annualAmount } = plan;
            const isAnnual = name === "yearly";
            return (
              <Col xs={24} sm={24} md={12} lg={12}>
                <StyledPlanCard
                  className="w-100 h-100"
                  classNames={{ body: "h-100" }}
                >
                  <Flex vertical justify="space-between" className="h-100">
                    <div>
                      <Text children={title} />
                      <Flex
                        gap=".5em"
                        align="baseline"
                        className="m-5-t m-20-b"
                      >
                        <Text
                          level={2}
                          children={Tools.toCurrency(
                            isAnnual ? annualAmount : amount
                          )}
                        />
                        <Text
                          children={`/ ${isAnnual ? "Annually" : "Monthly"}`}
                        />
                      </Flex>
                      <div
                        className="m-35-b"
                        children={React.Children.toArray(
                          infos.map((info) => (
                            <Flex gap=".5em">
                              <StarFilled style={{ color: "orange" }} />
                              <Text children={info} />
                            </Flex>
                          ))
                        )}
                      />
                    </div>
                    <Button
                      type="primary"
                      className="w-100"
                      title={Localization.general.choose}
                      onClick={() => onSubscribe({ ...plan, name })}
                    />
                  </Flex>
                </StyledPlanCard>
              </Col>
            );
          })
        )}
      />
    </div>
  );
};

export default PaymentPlanLayout;
