import { Enums, Tools } from "../utils";
import api from "./api";

const invites = (uuid = "") =>
  new Promise(async (resolve, reject) => {
    try {
      const data = await api.get(`/rest/groups/${uuid}/invites`);
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });

const sendInvites = (uuid = "", emails = []) =>
  new Promise(async (resolve, reject) => {
    try {
      const data = await api.post(`rest/v2/groups/${uuid}/invites`, {
        data: { invites: emails.map((email) => ({ email })) },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });

const groups = ({ sort = "name" } = {}) =>
  new Promise(async (resolve, reject) => {
    const member = Tools.storage.getItem(Enums.storageKeys.userUuid);
    try {
      const data = await api.get(`/rest/v2/groups`, {
        params: { member, sort },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });

const addGroup = (options) =>
  new Promise(async (resolve, reject) => {
    try {
      const data = await api.post(`/rest/groups`, { data: options });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });

const updatePrimaryGroup = (id, options) =>
  new Promise(async (resolve, reject) => {
    const userId = Tools.storage.getItem(Enums.storageKeys.userUuid);
    try {
      const data = await api.put(
        `/rest/users/${userId}/services/${id}/primarygroup`,
        { data: options }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });

export default { invites, groups, sendInvites, addGroup, updatePrimaryGroup };
