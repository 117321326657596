import Localization from "../../../localization";
import { Text } from "../../../components";
import React, { useContext } from "react";
import { ServiceContext } from "..";
import { Flex } from "antd";

const Phone = () => {
  const { device } = useContext(ServiceContext);

  return (
    <Flex gap={"1em"} align="center">
      {!!device?.alarm?.image && (
        <img src={device?.alarm.image} style={{ width: "7em" }} />
      )}
      <div>
        <Text level={4} children={device?.alarm?.name} />
        <Text
          children={`${Localization.general.phone_number}: ${
            device?.identifier ?? Localization.general.na
          }`}
        />
      </div>
    </Flex>
  );
};

export default Phone;
