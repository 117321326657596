import { Button, Form, Text } from "../../components";
import Localization from "../../localization";
import React, { useRef } from "react";

const UnavailableAlarm = ({ onSend = () => {} }) => {
  const formRef = useRef();
  return (
    <React.Fragment>
      <Text children={Localization.services.ua_alarm_modal_desc} />
      <Form
        className="m-20-v"
        ref={formRef}
        fields={[
          {
            name: ["data", "name"],
            rules: [{ required: true }],
            label: Localization.general.name,
            placeholder: Localization.general.firstLastName,
          },
          {
            type: "email",
            name: ["data", "username"],
            placeholder: "name@example.com",
            label: Localization.general.email_address,
            rules: [{ type: "email", required: true }],
          },
          {
            type: "phone",
            name: ["data", "phone"],
            placeholder: "0123 456 78 90",
            rules: [{ min: 4, required: true }],
            label: Localization.general.phone_number,
          },
        ]}
      />
      <Button
        type="primary"
        className="w-100"
        title={Localization.general.send}
        onClick={async () => {
          try {
            await formRef.current.validateFields();
            onSend();
          } catch (error) {}
        }}
      />
    </React.Fragment>
  );
};

export default UnavailableAlarm;
