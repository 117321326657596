import { Button, Text } from "../../components";
import Localization from "../../localization";
import React, { useContext } from "react";
import { ServiceContext } from ".";
import { Card } from "antd";

const GroupsInfo = ({ title = "", onEditClick = () => {} }) => {
  const { device } = useContext(ServiceContext);
  const iGroupEmpty = !device.distribution?.recipients?.groups?.length;

  return (
    <Card
      title={title}
      className="text-align-center border-transparent"
      actions={[
        <Text
          className="text-align-start p-20-h"
          type={iGroupEmpty ? "destructive" : "primary"}
        >
          <Text
            color="inherit"
            weight={500}
            children={
              iGroupEmpty
                ? Localization.service.card_4_footer_title_error
                : Localization.service.card_4_footer_title
            }
          />
          <Text
            color="inherit"
            children={
              iGroupEmpty
                ? Localization.service.card_4_footer_desc_error
                : Localization.service.card_4_footer_desc
            }
          />
        </Text>,
      ]}
    >
      <Text
        level={2}
        children={Localization.formatString(
          Localization.service.card_4_cont_title,
          device.distribution?.recipients?.nrOfRecipients ?? "",
          device.distribution?.recipients?.groups?.length ?? 0
        )}
      />
      <Text children={Localization.service.card_4_cont_desc} />
      <Button
        ghost
        type="primary"
        onClick={onEditClick}
        className="w-100 m-15-t"
        title={Localization.service.card_4_btn}
      />
    </Card>
  );
};

export default GroupsInfo;
