import { AuthContext, ContextType } from "../context";
import { useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Enums, Tools } from "../utils";
import { AuthApi } from "../apis";

const useAuth = () => {
  const { user, isAuthenticated, dispatch } = useContext(AuthContext);
  const navigate = useNavigate();

  const refreshUser = useCallback(async () => {
    const user = await AuthApi.me();
    dispatch(ContextType.auth.SET_USER, user);
  }, []);

  const login = useCallback(
    (data) =>
      new Promise(async (resolve, reject) => {
        try {
          const { token } = await AuthApi.login(data);
          const user = await AuthApi.meWithShortTermToken(token);
          Tools.storage.setItem(Enums.storageKeys.token, user.accessToken);
          dispatch(ContextType.auth.SET_USER, user);
          resolve();
        } catch (error) {
          reject(error);
        }
      }),
    []
  );

  const register = useCallback(
    (data) =>
      new Promise(async (resolve, reject) => {
        try {
          const { accessToken } = await AuthApi.register(data);
          Tools.storage.setItem(Enums.storageKeys.token, accessToken);
          await refreshUser();
          resolve();
        } catch (error) {
          reject(error);
        }
      }),
    []
  );

  const logout = useCallback(() => {
    dispatch(ContextType.auth.LOGOUT);
    navigate("/");
  }, []);

  return { user, isAuthenticated, login, logout, register, refreshUser };
};

export default useAuth;
