export default {
  general: {
    connect: "Connect",
    purchase: "Beställ",
    learn_more: "Läs mer",
    email_address: "E-post",
    password: "Lösenord",
    confirm_password: "Bekräftar lösenord",
    type_password: "Skriv in lösenord",
    placeholder: "Skriv in lösenord",
    forgot_password: "Glömt lösenord",
    login: "Logga in",
    register: "Registrera",
    name: "Namn",
    firstLastName: "För- och efternamn",
    region: "Land",
    select_region: "Välj Land",
    sweden: "Sverige",
    united_kingdom: "UK",
    yes: "Ja",
    no: "Nej",
    continue: "Fortsätt",
    cancel: "Avbryt",
    okay: "Ok",
    accept_terms: "Jag godkänner tjänstevillkoren",
    phone_number: "Telefonnummer",
    send: "Skicka",
    read_more: "Läs mer",
    order_it: "Beställ",
    monthly: "Månatlig",
    annually: "Årlig",
    save: "Spara",
    get_plan: "Se prenumeration",
    logout: "Logga ut",
    connect_plus: "Connect Plus",
    status: "Status",
    payment_method: "Betalsätt",
    step: "Steg",
    address: "Adress",
    note: "Anteckning",
    optional: "Frivillig",
    my_home: "Mitt hem",
    is_connected: "Uppkopplad",
    confirm: "Bekräfta",
    additional_info: "Mer information",
    good: "Bra",
    connect_alarm: "Koppla larmsystem",
    not_connected: "Ej uppkopplad",
    change_account: "Byt konto",
    connected: "Uppkopplad",
    connect_account: "Koppla konto",
    ajax_hubId: "Ajax Hub ID",
    na: "Inte tillgänglig",
    home_name: "Namn på hem",
    code_word: "Kodord",
    home_address: "Adress",
    postcode: "Postnummer",
    city: "Postort",
    your_full_name: "För- och efternamn",
    save_and_cont: "Spara och fortsätt",
    next: "Nästa",
    back: "Tillbaka",
    admin: "Administratör",
    add: "Lägg till",
    phone: "Telefon",
    email: "E-post",
    burglary: "Inbrott",
    fire: "Brand",
    fight: "Överfallslarm",
    emergency: "Paniklarm",
    sabotage: "Sabotage",
    tech: "Tekniskt fel",
    summary: "Sammanfattning",
    collaborate: "Samverka",
    flood: "Vattenlarm",
    done: "Klart",
    webhook_url: "Webhook URL",
    method: "Metod",
    request_body: "Request Body",
    group: "Grupp",
    public: "Publikt",
    locked: "Låst",
    private: "Privat",
    members: "Medlemmar",
    yes: "Ja",
    edit: "Redigera",
    delete: "Ta bort",
    test: "Test",
    safeland_connect: "Safeland Connect",
    subscription: "Abonnemang",
    service_desc: "Tjänstebeskrivning",
    total_value: "Totalt belopp",
    activation_code: "Aktiveringskod",
    oops: "Hoppsan!",
    activate: "Aktivera",
    new_password: "Nytt lösenord",
    year: "års",
    group_name: "Gruppnamn",
    group_type: "Typ",
    undo: "Ångra",
    voluntarily: "Frivilligt",
    yes: "Ja",
    no: "Nej",
    choose: "Välj",
  },
  htmlHeader: {
    desc: "Ställ in eller beställ ny Connect - med eller utan larmcentral",
  },
  placeholder: {
    home_name: "Välj ett namn",
    enter_home_address: "Lägg till hemaddress",
  },
  feedback: {
    invalid_password: "Lösenord måste innehålla minst 4 tecken",
    invalid_email: "Inte en giltig e-postadress",
    invalid_credentials: "Felaktigt användarnamn eller lösenord",
    something_wrong: "Något gick fel",
    forgot_password_success:
      "Vi har skickat ett mail till {0} med instruktioner för hur du ändrar lösenord.",
    unmatched_password: "Lösenorden stämmer inte överens",
    hub_added: "Hub: {0} är nu tillagt till Connect",
    enter_valid_url: "Lägg till URL",
    enter_valid_JSON: "Lägg till JSON",
    reset_pass_exp_token: "Tyvärr har återställningsnyckeln gått ut.",
    invalid_license_key: "Hoppsan! Felaktig kod. Försök igen.",
  },
  login: {
    footer: "Ny hos Safeland?",
  },
  register: {
    footer: "Har du redan Safeland-konto?",
  },
  forgot_password: {
    btn: "Skicka länk för att återställa lösenord",
    footer_1: "Logga in",
    footer_2: "Konto Safeland",
  },
  dashboard: {
    card_1_title: "Min Safeland Connect",
    card_1_desc: "Koppla upp eller anpassa din Connect-tjänst",
    card_1_btn: "Logga in",
    card_2_title: "Connect",
    card_2_desc: "Beställ ny Connect - med eller utan larmcentral",
    divider_title: "Connect to Safeland",
    divider_subtitle: "Beställ en av nedan tjänster",
    divider_subtitle_empty: "Din profil har ännu ingen Connect-tjänst",
    login_modal_title: "Logga in på ditt konto i Safeland",
    register_modal_title: "Skapa ett nytt Safeland-konto",
    forgot_password_modal_title: "Återställ lösenord",
    device_notconfig_desc:
      "Samtliga inställningar är inte klara. Tryck här för att slutföra inställningarna",
  },
  services: {
    title: "Safeland Connect",
    subtitle:
      "Koppla ditt hem till digital grannsamverkan och/eller larmcentral med tillgång till väktartjänsten.",
    info_1: "Snabbast hjälp från vänner och grannar i Safeland-appen",
    info_2: "Snabbast hjälp om larmet löser ut",
    info_3: "Lägg till 24/7 larmcentral och tillgång till väktartjänst",
    alarm_conf_para: "Har du redan ett larm som du vill koppla till Safeland?",
    alarm_list_title: "Välj ditt larm",
    success_title: "Välkommen till Safeland Connect",
    success_desc:
      "Tack för din beställning. Nästa steg är att koppla ditt larm till Safeland. Inställningarna går att ändra närsomhelst.",
    alarm_list_footer_title: "Connect alarm using HTTP",
    alarm_list_footer_subtitle:
      "Är ditt hemlarm inte i listan? Det kan gå ändå. Om ditt hemlarm kan trigga en URL med http, så kan du koppla det till Safeland med en webhook.",
    ua_alarm_modal_desc:
      "Den här leverantören är ett slutet larmsystem som inte går att koppla upp till Safeland eller externa system. Vi hjälper dig gärna om du önskar mer modernt hemlarm.",
    plan_modal_title: "Välj alternativ",
    plan_modal_subtitle:
      "Connect är tjänsten med grannar för snabbast åtgärd. Plus ger dig även certifierad larmcentral 24/7 och tillgång till väktartjänst.",
    plan_modal_dec:
      "Larmcentralen från Westra Security hjälper dig om olyckan är framme",
    alarm_pkg_subtitle: "Beställ ett av Safelands larmpaket",
    alarm_pkg_desc:
      "Prisbelönt hemlarm som du äger själv - utan konstiga villkor",
  },
  service: {
    error_title: "Din tjänst har inte rätt inställninga",
    error_desc:
      "Tjänsten är inte komplett eller korrekt uppsatt. Gå igenom inställningarna igen. Kontakta oss om du vill ha hjälp support@safe.land",
    monthly_pay: "Månatlig ({0} {1}/monthly) • Nästa betalning: {2}",
    annually_pay:
      "Årlig prenumeration ({0} {1}/årligen) • Nästa betalning: {2}",
    card_1_title: "Platsen för hemlarm",
    card_2_title: "Uppkoppling",
    card_3_title: "Larmcentral",
    card_5_title: "Min tjänst",
    card_1_btn: "Redigera plats",
    card_2_btn: "Redigera uppkoppling",
    card_3_btn: "Uppdatera larminstruktion",
    card_4_btn: "Hantera mottagare av larm",
    card_5_btn: "Updatera betalmetod",
    card_5_btn_2: "Avsluta tjänst",
    card_2_footer_sms_title: "Lägg till larmets telefonnummer",
    card_2_footer_sms_desc:
      "Med larmets telefonnummer kan vi identifiera ditt hem och aktivera åtgärd när larmet löser ut.",
    card_2_footer_ajax_title: "Lägg till larmets Hub ID",
    card_2_footer_ajax_desc:
      "HubID använder Safeland för att identifiera ditt larm och för att fånga upp larmmeddelanden.",
    card_4_footer_title: "Har du bjudit in alla?",
    card_4_footer_title_error: "Ställ in mottagare för Connect",
    card_4_footer_desc:
      "Bjud in fler personer till Safeland för ökad samverkan vid larm",
    card_4_footer_desc_error:
      "Skyddet är bäst från personer i närheten. Utan mottagare får du ingen nytta av Safeland-tjänsten.",
    card_4_cont_title: "{0} Personer i {1} Grupper",
    card_4_cont_desc: "tar emot larm från ditt larmsystem",
    unconf_title: "Välkommen till Safeland Connect!",
    unconf_p1: "Min prenumeration på Connect.",
    unconf_p2: "Nu sätter vi igång. Det är tre enkla steg - följ guiden nedan.",
    step_1_title: "Namnge larmets plats",
    step_1_empty_title: "Välj platsen och namnet för ditt larm",
    step_1_empty_btn: "Ställ in",
    step_2_title:
      "I detta steg gör du tekniska inställningar för att ditt hemlarm att anropa Safeland.",
    step_2_empty_title: "Koppla upp mitt larm",
    step_2_empty_btn: "Koppla upp",
    step_3_title: "Gör larminstruktion för larmcentralen",
    step_3_empty_title:
      "Larmcentralen agerar enligt din instruktion vid larm från ditt system. Här hanterar du dessa inställningar",
    step_3_empty_btn: "Till larminstruktion",
    step_4_title: "Lägg till grupper för samverkan",
    step_4_empty_title:
      "Connect skickar larm till din valda grupp(er) i Safeland. Välj en ny grupp eller koppla ditt larm till en befintlig som du är medlem i.",
    step_4_empty_btn: "Lägg till",
    edit_loc_title: "Välj eller skapa ditt hem",
    edit_loc_desc: "Välj ett befintligt hem eller lägg till ett nytt hem",
    add_home_item_title: "Lägg till ett nytt hem",
    add_home_item_desc: "Lätt till ett nytt hem på önskad plats",
    add_home_modal_title: "Mitt hem",
    alarm_operator_status_error:
      "Väntar på bekräftelse på uppkoppling av larmcentralen",
    alarm_operator_title: "Kopplad till Westra Security",
    alarm_operator_footer_title: "Lägg till och slutför en larminstruktion",
    alarm_operator_footer_desc:
      "Larmcentralen behöver en aktiv larminstruktion för att agera när larmet löser ut.",
    edit_alarm_connection_title: "Fabrikat på mitt larm",
    edit_alarm_connection_config_modal_title:
      "Koppla ditt larmsystem till Safeland",
    edit_alarm_connection_desc_1:
      "Det är dags att koppla larmet till Safeland!",
    edit_alarm_connection_desc_2: "Bekräftar fabrikat på larm.",
    edit_alarm_connection_btn_1: "Bekräfta & Fortsätt",
    edit_alarm_connection_btn_2: "Välj ett annat alarm",
    edit_alarm_connection_alarms_desc_1:
      "Det är dags att koppla hemlarmet till Safeland Connect",
    edit_alarm_connection_alarms_desc_2: "Välj fabrikat på hemlarm",
    edit_alarm_connection_ajax_desc_1: "Har du bytt Ajax hub?",
    edit_alarm_connection_ajax_desc:
      "Välj hubben - valet ersätter tidigare hub.",
    edit_alarm_connection_ajax_login_desc: "Inloggad hos Ajax som: {0}",
    edit_alarm_connection_ajax_login_title:
      "Logga in på ditt Ajax-konto för att koppla en hub till Safeland",
    edit_payment_info_del_title: "Säg upp tjänst?",
    edit_payment_info_del_desc:
      "Är du säker på att du vill avsluta tjänsten? Tjänsten är aktiv till dess att din förbetalda period tar slut {0}.",
    edit_payment_info_del_btn: "Ja, jag vill säga upp tjänsten",
    payment_info_modal_title: "Registrera nytt betalkort",
    payment_info_canceled_desc:
      "Du har nu avslutat din prenumeration. Om du skulle ångra dig går det bra genom att kontakta Safelands support.",
    payment_info_canceled_footer_title: "Prenumerationen uppsagd",
    payment_info_canceled_footer_desc: "Tjänsten är aktiv till {0}",
    payment_activate_footer:
      "Viktigt! Du behöver lägga in kortuppgifter för att förhindra att din tjänst avslutas vid slutet av prenumerationsperioden. Beloppet debiteras vid ny period.",
    payment_invoice_title: "Fakturakund",
    payment_invoice_desc:
      "Kontakta support@safe.land för att att hantera uppgifter eller avsluta prenumeration.",
  },
  add_home: {
    desc: "Bekräfta adress och ge även hemmet ett namn.",
    placeholder_1: "t.ex. hemma, sommarstugan",
    placeholder_2: "Sök och välj adressen",
    error_msg_2:
      "Adressen är inte komplett. Sök fram och välj full adress i listan",
    placeholder_3:
      "Lägg till information som kan vara bra för larmmottagare i Safeland vid larm, t.ex. gula huset, eller dörrkod",
    add_info_placeholder: "Gula huset, ingång till högra dörren med kod 1739",
  },
  service_verisure: {
    desc: "Koppla upp Verisure i två enkla steg. Börja med att logga in på Verisure Mina Sidor på webben.",
    step_1: "Lägg till Safelands telefonnummer som mottagare av larm",
    step_1_1:
      "Lägg till Safeland som mottagare av SMS för Brand och/eller Inbrott: {0}",
    step_1_2: "Köp SMS på Mina Sidor",
    step_2: "Lägg till din unika identifierare",
    step_2_1:
      "Använd koden för att namnge ditt Verisure larmsystem. Klicka på koden för att kopiera den.",
  },
  service_webhook: {
    desc_1:
      "Här är en unik webhook för ditt hemlarm. När ditt larm löser ut kommer denna anropa denna webhook som ett HTTP POST förfrågan. Tryck på webhooken för att kopiera den.",
    desc_2: "Följ dessa anvisningar om du använder du larm med IFTTT:",
  },
  service_edit_phone: {
    title: "Lägg till Safelands telefonnummer i inställningarna för ditt larm",
    desc_1:
      "När larmet löser ut skickas ett SMS till en lista på telefonnummer. Det är på det sättet som Safeland tar emot ditt larm.",
    desc_2:
      "Följ din hemlarms instruktion och lägg till Safeland som en mottagare. Vårt nummer är:",
    condition: "Jag har lagt till Safeland som larmmottagare",
    number_modal_title: "Telefonnummer alarm",
    number_modal_desc:
      "Telefonnummer till ditt hemlarm. Detta är så att Safeland kan identifiera ditt hem och att det tillhör dig.",
  },
  service_edit_alarm_operator: {
    step1_title: "Personlig information",
    step1_desc:
      "Fyll i informationen nedan. Informationen delas med larmcentralen. Kodord är frivilligt och kommer att efterfrågas av larmoperatören om den är ifylld.",
    contacts_title: "Mottagare genom samtal vid larm",
    contacts_desc:
      "Utse personer som blir uppringda av larmcentralen när ditt larm löser ut. Kodord är frivilligt men kommer efterfrågas av larmoperatören vid larm om det är ifyllt.",
    contacts_form_title: "Lägg till kontaktpersoner",
    contacts_form_desc: "Fyll i formuläret och klicka ’Lägg till’",
    contacts_admin_desc:
      "Larmmottagaren har full befogenhet vid larm samt att ändra larminstruktionen, exempelvis tillkalla väktare.",
    contacts_table_title: "Kontaktpersoner telefonlista",
    actions_title: "Välj de larmtyper som ska skicka larm till dina grupper",
    actions_desc_1: "Du beslutar larmoperatörens åtgärder för olika larmtyper.",
    actions_desc_2:
      "Notera: Vid larmutryckning utgår normalt en kostnad vid väktarutryckning. Se tjänstens villkor för debitering.",
    actions_option_1: "Ingen åtgärd",
    actions_option_2: "Skicka alltid väktare direkt",
    actions_option_3:
      "Kontakta kontaktpersoner dagtid, vid uteblivet svar och övrig tid väktare för kontroll.",
    actions_option_4:
      "Kontakta kontaktpersoner dygnet runt, vid uteblivet svar väktare för kontroll",
    actions_option_5:
      "Kontakta kontaktpersoner dygnet runt, inga mer åtgärder.",
    summary_detail_1_title: "Larmcentral och Safelandappen",
    summary_detail_1_desc:
      "Larmcentralen kan inte ta del av dina grannars samverkanskommunikation i Safeland-appen.",
    summary_detail_2_title: "Kostad för väktarutryckning",
    summary_detail_2_desc:
      "Vid nyttjande av väktartjänster i linje med anvisningarna i ovan åtgärdsplan tillkommer en avgift enligt tjänstens aktuella villkor. Faktura skickas till angiven e-postadress med 20 dagars betalningsvillkor. Avgift för väktartjänster utgår inte vid skarpa inbrottsförsök i privathushåll. Styrkande av inbrottsförsök sker genom att mail med kopia av polisanmälan. Vid aktivering av Larmcentral-tjänsten för företag utgår en extra årlig avgift på 25%, vilken faktureras separat.",
    summary_detail_3_title: "Hantering av information och generella villkor",
    summary_detail_3_desc:
      "Tjänsten innebär att Safeland och leverantören Westra Security AB har tillgång till personlig information. Denna information består av ovan uppgifter samt den information som genereras av Safeland-tjänsten och hemlarmet. Informationen är nödvändig för att leverera tjänsten till dig som kund.",
  },
  service_edit_collaborators: {
    desc: "Välj de som ska ta emot dina larm. Det kan vara grannar, vänner, familj eller andra du litar på.",
    count_desc_1: "Just nu har du",
    count_desc_2: "som kan ta emot dina larm",
    invite_title_placeholder: "{0}'s hemlarm",
    invite_desc:
      "Skapa en egen grupp i Safeland där ni kan samverka i appen. Till den kan du bjuda in personer i närheten som du gillar. Safeland-appen är alltid gratis för alla.",
    invite_btn: "Bjud in fler",
    invite_btn_2: "Skapa en ny grupp",
    invite_footer: "Väntar på svar från {0} inbjudningar",
    invite_footer_placeholder: "Ännu ingen inbjuden till gruppen",
    group_title: "Koppla till grupper i Safeland",
    group_empty_desc_1:
      "Safeland används av många lokala trygghetsgrupper som samverkar för en säkrare och trivsammare tillvaro. Saknar du lämplig grupp att samverka med så rekommenderar vi att du startar med 3-5 grannar. Det är ofta någon hemma och ni kan alla hjälpa varandra i chatten",
    group_empty_desc_2:
      "Om du är medlem i en grupp, så kan du även koppla ditt larm till gruppen. Efter att du har slutfört inställningarna här, ta gärna en titt på vilka grupper som finns i ditt område!",
    group_desc:
      "Du kan också koppla ditt larm till dina andra Safeland-grupper, som till exempel en grannsamverkansgrupp.",
    group_btn: "Välj grupper",
    group_footer:
      "Tips: du kan hitta och gå med i befintliga grupper i Safeland-appen",
    actions_desc:
      "Välj de larmtyper som du vill skall skickas ut till ovan valda grupper i Safeland",
    hook_title: "Smart Home Integration (Beta)",
    hook_desc: "Safeland kan anropa en extern webhook när ditt larm löser ut.",
    hook_btn: "Anpassa webhook",
    invite_modal_title: "Bjud in med e-post",
    invite_modal_desc:
      "Bjud in personer till att bli larmmottagare när ditt larm går.",
    invite_modal_input_label:
      "Skriv en eller flera mailadresser i fältet (Separera dem med mellanslag eller enter).",
    invite_modal_input_placeholder: "Skriv en eller flera e-postadresser",
    invite_modal_btn: "Bjud in",
    group_modal_title: "Koppla till existerande Safeland-grupper",
    group_modal_desc:
      "Koppla ditt larm till en eller flera av dina grupper i Safeland. Gruppens medlemmar kommer att få en notis i appen om att du ansluter ditt hemlarm.",
    webhook_modal_title: "Anropa en extern webhook",
    webhook_modal_desc:
      "Safeland kan anropa en extern webhook när ditt larm löser ut. Ange informationen i formuläret nedan för att konfigurera webhook-requesten.",
    webhook_del_title: "Ta bort webhook",
    webhook_del_desc: "Är du säker på att du vill ta bort webhook?",
    webhook_test_title: "Testa min webhook",
    webhook_test_desc:
      "Tryck på knappen Skicka för att skicka ett testanrop till din webhook",
    new_group_modal_title: "Skapa Grupp",
  },
  not_found: {
    title: "Ursäkta, det blev något fel från vår sida.",
    desc: "Ursäkta, den här länken hittar vi inte. Kontakta gärna support@safe.land",
  },
  activate: {
    header_title: "Aktivera tjänsten",
    footer_desc: "Tjänsten är redan aktiverad!",
    unauth_title: "Aktivera",
    unauth_desc:
      "Kom igång genom att skapa ett nytt konto på Safeland eller logga in på ditt befintliga.",
    error:
      "Något har blivit fel, kontakta support@safe.land om problemet kvarstår",
    success_title: "Bravo! Tjänsten är nu aktiverad.",
    success_desc:
      "Din tjänst är aktiverad. Nu återstår kopplingen av ditt larmsystem till Safeland för ett tryggare hem.",
    success_btn: "Genomför inställningarna",
    invalid_title: "Skriv in din aktiveringskod",
    invalid_desc:
      "Skriv in din unika aktiveringskod nedan för att aktivera din tjänst.",
  },
  reset_password: {
    title: "Välj ett nytt lösenord",
    desc: "Snart klar! Skriv in ditt nya lösenord nedan för att återställa lösenordet",
    btn: "Uppdatera mitt lösenord",
    invalid_link_title: "Felaktig återställningslänk",
    invalid_link_desc:
      "Åh nej! Det verkar som att din återställningslänk inte är giltig längre.",
    success_feedback: "Lösenordet har uppdaterats för {0}.",
  },
  api: {
    services: {
      devices: {
        terms:
          "Grattis! Det går att koppla Verisure larm till Safeland. Du behöver inte ha abonnemang hos Verisure..\n\n Safeland Connects ger dig snabb åtgärd tillsammans med vänner och grannar. Connect Plus ger dig även larmcentralstjänsten som arbetar för dig 24/7.",
      },
      devicePackages: {
        folklarmet_desc:
          "An impressive home alarm kit that doesn’t cost the earth. The Sikkerthjem S6evo is great value for money and contains all the fundamental components that a modern home alarm system needs.",
        premiumlarmet_desc:
          "Det prisbelönta larmet som imponerar. Högteknologiskt och användarvänligt. Läs mer och beställ på www.safe.land",
      },
      paymentPlans: {
        basic_infos: [
          "Snabbast åtgärd vid larm",
          "Larm till vänner och grannar",
          "Koppling till smarta hem",
        ],
        plus_infos: [
          "Connect tjänsten",
          "Certifierad larmcentral 24/7",
          "Tillgång till väktartjänst",
        ],
      },
    },
  },
};
