import { AutoComplete as AntAutoComplete, Spin } from "antd";
import React, { useState } from "react";

const AutoComplete = ({ onSearch = async () => [], ...props }) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  return (
    <AntAutoComplete
      options={options}
      suffixIcon={loading && <Spin size="small" />}
      onSearch={async (val) => {
        setLoading(true);
        try {
          const options = await onSearch(val);
          if (Array.isArray(options))
            setOptions(options.map((_, i) => ({ ..._, key: i.toString() })));
        } catch (error) {
        } finally {
          setLoading(false);
        }
      }}
      {...props}
    />
  );
};

export default AutoComplete;
