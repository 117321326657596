// import axios from "axios";
// import { Constants } from "../utils";

import axios from "axios";
import { Constants } from "../utils";

const placeTextSearch = async (textQuery = "", { fields = [] } = {}) =>
  new Promise(async (resolve, reject) => {
    try {
      if (!textQuery) return resolve({ places: [] });
      const { data } = await axios({
        method: "post",
        url: `/places:searchText`,
        baseURL: "https://places.googleapis.com/v1",
        data: { textQuery },
        headers: {
          "Content-Type": "application/json",
          "X-Goog-Api-Key": Constants.Project.googleApiKey,
          "X-Goog-FieldMask": !fields.length
            ? "*"
            : fields.map((field) => `places.${field}`).join(","),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });

export default { placeTextSearch };
