import React, { useCallback } from "react";
import styled from "styled-components";
import { List, Tag, theme } from "antd";

const StyledListItem = styled(List.Item)`
  position: relative;
  ${(_) => _.disabled && `pointer-events: none;`}
  ${(_) =>
    _.hoverable &&
    !_.disabled &&
    ` &:hover {
  cursor: pointer;
  transition: 0.5s ease;
  background: rgba(0, 0, 0, 0.05);
}`}
`;

const ListItem = ({
  title = "",
  description = "",
  avatarImg = "",
  extraImg = "",
  disabled = false,
  bordered = false,
  item = {},
  itemMeta = {},
  hoverable = false,
  onClick = () => {},
  tags = [],
  ...props
}) => {
  const { token } = theme.useToken();

  const renderItem = useCallback(() => {
    return (
      <StyledListItem
        onClick={onClick}
        disabled={disabled}
        hoverable={hoverable}
        extra={
          !!extraImg && (
            <img style={{ width: "5em", height: undefined }} src={extraImg} />
          )
        }
        {...item}
      >
        <List.Item.Meta
          title={title}
          description={description}
          avatar={
            !!avatarImg && (
              <img
                style={{ width: "3em", height: undefined }}
                src={avatarImg}
              />
            )
          }
          {...itemMeta}
        />
        {!!tags.length && (
          <div
            style={{ position: "absolute", top: 8, right: 2 }}
            children={React.Children.toArray(
              tags
                .filter((_) => !!_)
                .map((tag) => <Tag color={token.fontColors.success} {...tag} />)
            )}
          />
        )}
      </StyledListItem>
    );
  }, [title, description, avatarImg, extraImg, item, itemMeta]);

  if (bordered)
    return (
      <List
        bordered
        dataSource={[{}]}
        itemLayout="horizontal"
        renderItem={renderItem}
        {...props}
      />
    );
  return renderItem();
};

export default ListItem;
