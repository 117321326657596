export default {
  general: {
    connect: "Connect",
    purchase: "Purchase",
    learn_more: "Learn more",
    email_address: "Email address",
    password: "Password",
    confirm_password: "Confirm password",
    type_password: "Type your password",
    placeholder: "Type your password",
    forgot_password: "Forgot password",
    login: "Login",
    register: "Register",
    name: "Name",
    firstLastName: "First and last name",
    region: "Region",
    select_region: "Select region",
    sweden: "Sweden",
    united_kingdom: "United kingdom",
    yes: "Yes",
    no: "No",
    continue: "Continue",
    cancel: "Cancel",
    okay: "Okay",
    accept_terms: "I accept the terms and conditions",
    phone_number: "Phone number",
    send: "Send",
    read_more: "Read more",
    order_it: "Order it",
    monthly: "Monthly",
    annually: "Annually",
    save: "Save",
    get_plan: "Get plan",
    logout: "Logout",
    connect_plus: "Connect plus",
    status: "Status",
    payment_method: "Payment method",
    step: "Step",
    address: "Address",
    note: "Note",
    optional: "Optional",
    my_home: "My home",
    is_connected: "Is connected",
    confirm: "Confirm",
    additional_info: "Additional information",
    good: "Good",
    connect_alarm: "Connect alarm",
    not_connected: "Not connected",
    change_account: "Change account",
    connected: "Connected",
    connect_account: "Connect account",
    ajax_hubId: "Ajax Hub ID",
    na: "Not available",
    home_name: "Home name",
    code_word: "Code word",
    home_address: "Home address",
    postcode: "Postcode",
    city: "City",
    your_full_name: "Your full name",
    save_and_cont: "Save & Continue",
    next: "Next",
    back: "Back",
    admin: "Admin",
    add: "Add",
    phone: "Phone",
    email: "Email",
    burglary: "Burglary",
    fire: "Fire",
    fight: "Fight",
    emergency: "Emergency",
    sabotage: "Sabotage",
    tech: "Tech",
    summary: "Summary",
    collaborate: "Collaborate",
    flood: "Flood",
    done: "Done",
    webhook_url: "Webhook URL",
    method: "Method",
    request_body: "Request Body",
    group: "Group",
    public: "Public",
    locked: "Locked",
    private: "Private",
    members: "Members",
    yes: "Yes",
    edit: "Edit",
    delete: "Delete",
    test: "Test",
    safeland_connect: "Safeland Connect",
    subscription: "Subscription",
    service_desc: "Service description",
    total_value: "Total value",
    activation_code: "Activation code",
    oops: "Oops!",
    activate: "Activate",
    new_password: "New password",
    year: "year",
    group_name: "Group name",
    group_type: "Group type",
    undo: "Undo",
    voluntarily: "Voluntarily",
    yes: "Yes",
    no: "No",
    choose: "Choose",
  },
  htmlHeader: {
    desc: "Configure or purchase a new Connect - with or without alarm central",
  },
  placeholder: {
    home_name: "Vacation home",
    enter_home_address: "Enter your home address",
  },
  feedback: {
    invalid_password: "Password must contain at least 4 characters",
    invalid_email: "Please a enter valid email",
    invalid_credentials: "Invalid credentials",
    something_wrong: "Something went wrong",
    forgot_password_success:
      "We've sent you an email to {0} with instructions on how to reset your password. Take a look in your inbox.",
    unmatched_password: "The new password that you entered do not match!",
    hub_added: "Hub: {0} added to connect",
    enter_valid_url: "Please enter valid url",
    enter_valid_JSON: "Please enter valid JSON",
    ua_service: "Requested service in unavailable",
    reset_pass_exp_token:
      "Sorry, but it seems the reset token you're using has expired.",
    invalid_license_key: "Invalid license key",
  },
  login: {
    footer: "New to safeland?",
  },
  register: {
    footer: "Already have account?",
  },
  forgot_password: {
    btn: "Send password resend link",
    footer_1: "Log in to your",
    footer_2: "Safeland account",
  },
  dashboard: {
    card_1_title: "Your safeland connect",
    card_1_desc:
      "Setting up a brand new connection? Configuring an existing one? Get started or update your settings here.",
    card_1_btn: "Login or register",
    card_2_title: "Connect",
    card_2_desc: "Protect your house and property",
    divider_title: "Connect to Safeland",
    divider_subtitle: "Choose one of our products below",
    divider_subtitle_empty:
      "This account does not currently have a Connect-subscription.",
    login_modal_title: "Log in to your Safeland account",
    register_modal_title: "Register your Safeland account",
    forgot_password_modal_title: "Reset your password",
    device_notconfig_desc:
      "This connection is not yet fully configured. Press here to finish the configuration.",
  },
  services: {
    title: "Safeland Connect",
    subtitle: "Connect your home alarm to Safeland for enhanced home security.",
    info_1: "Connect your home to trusted neighbours and friends",
    info_2: "Get the fastest response to triggered alarms",
    info_3: "Easily add a central alarm operator and security guards",
    alarm_conf_para:
      "Do you currently own an alarm that you want to connect to Safeland?",
    alarm_list_title: "Select your existing alarm",
    success_title: "Welcome to Safeland Connect",
    success_desc:
      "Thank you for your subscription. Now, let’s get your alarm connected and running!. You can edit, update and add things whenever you like.",
    alarm_list_footer_title: "Connect alarm using HTTP",
    alarm_list_footer_subtitle:
      "Alarm not in this list? No problem. If your alarm is able to ‘call’ a URL using HTTP, you can connect it to Safeland with a webhook.",
    ua_alarm_modal_desc:
      "This alarm provider is not currently compatible with Safeland Connect. However, if you provide your contact details below, we can get in touch to discuss the best solution for you.",
    plan_modal_title: "Choose your plan",
    plan_modal_subtitle: "Connect Plus - Alarm central with security guards",
    plan_modal_dec:
      "Our alarm central applies the most suitable response when you alarm gets triggered",
    alarm_pkg_subtitle: "Order one of our home alarm packages",
    alarm_pkg_desc:
      "Kickstart your home security with one of our packages. No complicated conditions or hidden fees. You can easily customise your package, and add extra services and products as you like!",
  },
  service: {
    error_title: "Your service is not working as expected",
    error_desc:
      "In order to get your Connect Homes rolling again, please fix any issues below. If you cannot resolve the issue please contact us at support@safe.land.",
    monthly_pay: "Monthly subscription ({0} {1}/monthly) • Next payment: {2}",
    annually_pay:
      "Annually subscription ({0} {1}/annually) • Next payment: {2}",
    card_1_title: "Alarm location details",
    card_2_title: "Alarm connection",
    card_3_title: "Alarm operator",
    card_5_title: "Your service",
    card_1_btn: "Edit location",
    card_2_btn: "Edit connection details",
    card_3_btn: "Modify alarm operator response",
    card_4_btn: "Add/Edit collaborators",
    card_5_btn: "Update payment method",
    card_5_btn_2: "Cancel service",
    card_2_footer_sms_title: "You need to provide your alarm's phone number",
    card_2_footer_sms_desc:
      "Without the phone number of your alarm we can never know when your alarm is triggered",
    card_2_footer_ajax_title: "You need to provide your alarm's Hub ID",
    card_2_footer_ajax_desc:
      "Without the Hub ID of your alarm we can never know when your alarm is triggered",
    card_4_footer_title: "Did you invite everyone?",
    card_4_footer_title_error:
      "Add recipients by connecting your alarm to groups",
    card_4_footer_desc:
      "Invite more people and remind them to accept the invitations",
    card_4_footer_desc_error:
      "With no recipients Connect cannot communicate with your peers",
    card_4_cont_title: "{0} People in {1} Group",
    card_4_cont_desc: "are recipients of your alarms",
    unconf_title: "Welcome to Safeland Connect!",
    unconf_p1: "This is your Connect subscription.",
    unconf_p2:
      "Let’s get your service up and running. The setup is three steps - follow the guide below.",
    step_1_title: "Set alarm location",
    step_1_empty_title: "Set alarm location",
    step_1_empty_btn: "Set alarm location",
    step_2_title:
      "In this step, you make technical settings for your home alarm to call Safeland.",
    step_2_empty_title: "Connect your alarm",
    step_2_empty_btn: "Connect your alarm",
    step_3_title: "Customize alarm operator",
    step_3_empty_title: "Customize alarm operator",
    step_3_empty_btn: "Customize alarm operator",
    step_4_title: "Add groups to collaborate",
    step_4_empty_title:
      "Connect sends alarms to your chosen group(s) in Safeland. Select a group or connect your alarm to an existing group that you are a member of",
    step_4_empty_btn: "Add collaborators",
    edit_loc_title: "Your home’s location",
    edit_loc_desc:
      "Select an existing home or add a new home where you will use your Safeland Connect service.",
    add_home_item_title: "Add a new home",
    add_home_item_desc: "Add a new home on your preferred location",
    add_home_modal_title: "Your homes information",
    alarm_operator_status_error: "Waiting for ARC confirmation",
    alarm_operator_title: "Connected to Westra Security",
    alarm_operator_footer_title:
      "You need to provide a valid alarm instruction",
    alarm_operator_footer_desc:
      "Without an alarm instruction, the alarm central can not take action when your alarm is triggered",
    edit_alarm_connection_title: "Your alarm model",
    edit_alarm_connection_config_modal_title:
      "Connect your alarm system to Safeland",
    edit_alarm_connection_desc_1:
      "Now it's time to connect your alarm to Safeland.",
    edit_alarm_connection_desc_2: "Please confirm your alarm model.",
    edit_alarm_connection_btn_1: "Confirm & Continue",
    edit_alarm_connection_btn_2: "Choose a different alarm",
    edit_alarm_connection_alarms_desc_1:
      "Now it's time to connect your alarm to Safeland.",
    edit_alarm_connection_alarms_desc_2: "Please select your alarm model.",
    edit_alarm_connection_ajax_desc_1:
      "Have you upgraded or wish to change your Ajax hub?",
    edit_alarm_connection_ajax_desc:
      "Select the hub you wish to connect - this will replace your currently connected hub.",
    edit_alarm_connection_ajax_login_desc: "Logged in to Ajax as: {0}",
    edit_alarm_connection_ajax_login_title: "Log in tou your Ajax account",
    edit_payment_info_del_title: "Cancel service?",
    edit_payment_info_del_desc:
      "Are you sure you want to cancel? By cancelling your service, your home will no longer be protected by nearby friends and neighbours. Your service will remain active until {0} as you have paid for the service until this time.",
    edit_payment_info_del_btn: "Yes, i want to cancel",
    payment_info_modal_title: "Register a new credit card",
    payment_info_canceled_desc:
      "You have cancelled your subscription. Please email us with any feedback or if you want to reactivate your subscription.",
    payment_info_canceled_footer_title: "Subscription is cancelled",
    payment_info_canceled_footer_desc: "Your service is valid to {0}",
    payment_activate_footer:
      "Important, update your payment method to prevent the subscription to be terminated at the end of period.",
    payment_invoice_title: "Invoice customer",
    payment_invoice_desc:
      "Contact support@safe.land to manage data or unsubscribe.",
  },
  add_home: {
    desc: "Please confirm or add to your home’s address. Be sure to give your home a personal name, too.",
    placeholder_1: "e.g at home, the summer cottage",
    placeholder_2: "Enter  your address",
    error_msg_2:
      "The address is not complete. Search for and select the full address in the list",
    placeholder_3:
      "Please note any additional information that your alarm recipients may need to know if they respond to your triggered alarm. Examples include: Door code, if a dog is present, specific location of front door, etc.",
    add_info_placeholder:
      "i.e Yellow house entrance on the back, access code is 1739. beware of dogs",
  },
  service_verisure: {
    desc: "Connecting your Verisure alarm to Safeland is done in two simple steps. You do this by logging in to your Verisure account on the web.",
    step_1: "Add Safeland's phone number as an alarm recipient",
    step_1_1: "Add Safeland as an SMS recipient: {0}",
    step_1_2: "Purchase SMS credits",
    step_2: "Add your unique identifier",
    step_2_1:
      "Set the code below as the name of your Verisure alarm system. Click the code to copy it.",
  },
  service_webhook: {
    desc_1:
      "Below is the unique webhook for your alarm device. Your alarm will need to call this webhook, using an HTTP POST request, to trigger an alarm in Safeland. Press the webhook to copy it.",
    desc_2:
      "If you are using an IFTTT alarm, follow these instructions on how to use this webhook with IFTTT.",
  },
  service_edit_phone: {
    title: "Add Safeland phone number to your alarm",
    desc_1:
      "If your alarm is tripped, it sends an SMS to a list of phone numbers. This is how Safeland will receive your alarm.",
    desc_2:
      "Follow your alarm’s instructions and add Safeland as a recipient. Our number is:",
    condition: "I confirm I have added Safeland number as an alarm recipient",
    number_modal_title: "Alarm phone number",
    number_modal_desc:
      "Enter the phone number of your alarm. This is your unique identifier, and how we know the alarm belongs to you.",
  },
  service_edit_alarm_operator: {
    step1_title: "Customer information",
    step1_desc:
      "Complete your details below - this will be shared with your alarm operator. Code word is optional and, if entered, will be requested by the alarm operator.",
    contacts_title: "Nominated call list",
    contacts_desc:
      "Below you can nominate people who you’d like your Alarm Operator to call when your alarm is triggered. Code words are optional and, if entered, will be requested by the alarm operator.",
    contacts_form_title: "Add nominee",
    contacts_form_desc: "Complete the form below and click ‘Add’",
    contacts_admin_desc:
      "Nominees with admin authority has full access to modify alarm operator customizations.",
    contacts_table_title: "Nominated call list",
    actions_title: "Actions for triggered alarms",
    actions_desc_1:
      "You can decide what action the alarm operator should take in the following alarm scenarios.",
    actions_desc_2:
      "Note: Sending a guard will be invoiced with 2100 SEK if crime could not be verified.",
    actions_option_1: "No action",
    actions_option_2: "Send guards to property",
    actions_option_3:
      "Contact nominees during the day. Other times and times where there is no response, control given to guards",
    actions_option_4:
      "Contact nominees at any time. If no response, control given to guards",
    actions_option_5: "Contact nominees at any time. No further action.",
    summary_detail_1_title: "The alarm operator and the Safeland app",
    summary_detail_1_desc:
      "Larmcentralen kan inte ta del av dina grannars samverkanskommunikation i Safeland-appen.",
    summary_detail_2_title: "Security Guard Services fees",
    summary_detail_2_desc:
      "If you are utilising the security guard service as part of your action plan outlined above, a fee of 2100SEK, including VAT, will be issued each time security guards are called out to your home. An invoice will be sent to your specified email address and carry a 20 day payment term. In cases where a burglary attempt to your home has been confirmed, the ‘call out’ fee is waived. The proof of a burglary attempt can be strengthened by the presence of a police report. The alarm operator service is a consumer product. Business customers will be charged an additional 490SEK per year.",
    summary_detail_3_title:
      "Management of your information and general conditions",
    summary_detail_3_desc:
      "Your security service allows Safeland and subcontractors to have access to personal information. This personal information consists of the information given above, as well as the information generated by the Safeland service and associated home alarm. This information is necessary to deliver the service to you, the Customer, as well as to meet requirements alarm operators must comply with as part of the current standard. If guards are required to be dispatched, the relevant information described in this document will be issued to the guard company.",
  },
  service_edit_collaborators: {
    desc: "Choose who receives your alarm if it's triggered. I.e. friends, neighbours, and family members.",
    count_desc_1: "You currently have",
    count_desc_2: "able to receive your alarm",
    invite_title_placeholder: "{0}'s home alarm group",
    invite_desc: "Invite your nearest and dearest to your own private group.",
    invite_btn: "Invite more people",
    invite_btn_2: "Create new group",
    invite_footer: "{0} pending invitations",
    invite_footer_placeholder: "No one has been invited yet",
    group_title: "Connect to Safeland-groups",
    group_empty_desc_1:
      "Safeland plays host to all kinds of local, safety-related groups, such as Neighbourhood Watch groups, local police-led groups or community support groups.",
    group_empty_desc_2:
      "If you're a member of these groups, you can request your home alarm to be shared with those members, too. When you have finished setting up your alarm, be sure to discover what groups exist around you!",
    group_desc:
      "You can also add your home alarm to your other Safeland-groups, such as a neighbourhood watch group.",
    group_btn: "Select groups",
    group_footer:
      "Tip: you can find and join existing groups in the Safeland app",
    actions_desc:
      "Select the alarm types that you wish to be forwarded to your groups in Safeland",
    hook_title: "Smart Home Integration (Beta)",
    hook_desc:
      "Safeland can call a remote service using a webhook when your alarm is triggered.",
    hook_btn: "Configure webhook",
    invite_modal_title: "Invite people via email",
    invite_modal_desc:
      "Invite people directly to be collaborators of your alarm.",
    invite_modal_input_label: `Add one or multiple email addresses (Separate them with a "Space" or "Enter").`,
    invite_modal_input_placeholder: "Enter your email(s)",
    invite_modal_btn: "Send invitations",
    group_modal_title: "Connect to existing Safeland-groups",
    group_modal_desc:
      "Add your home alarm to one or more of your groups. Members of these groups will be notified that your home alarm has been added.",
    webhook_modal_title: "Call an external webhook",
    webhook_modal_desc:
      "Safeland can call an external webhook when your alarm is triggered. Enter data below to setup the webhook request.",
    webhook_del_title: "Delete the external webhook",
    webhook_del_desc: "Are you sure that you want to delete the webhook?",
    webhook_test_title: "Test your webhook configuration",
    webhook_test_desc:
      "Press the Send button to send a test request to the webhook.",
    new_group_modal_title: "Create a new group",
  },
  not_found: {
    title: "Sorry the page not found",
    desc: "The link you followed probably broken or the page has been removed.",
  },
  activate: {
    header_title: "Activate your service",
    footer_desc: "Service already activated!",
    unauth_title: "Start activation",
    unauth_desc:
      "To get started, please log into your Safeland account or create a new account.",
    error: "Something seems wrong with your license",
    success_title: "Activation successful!",
    success_desc:
      "Your Connect service is now activated and ready to be used! Now, connect your home alarm to your Connect subscription.",
    success_btn: "Configure alarm",
    invalid_title: "Enter your activation code",
    invalid_desc:
      "To activate your service, enter your unique activation code below.",
  },
  reset_password: {
    title: "Choose a new password",
    desc: "You're almost there! Enter your new password below to reset the password for your account associated with",
    btn: "Reset my password",
    invalid_link_title: "Invalid Reset Password Link",
    invalid_link_desc:
      "Uh-oh! It looks like the reset password link you clicked on is no longer valid. Please double-check the link and try again.",
    success_feedback: "Password has been successfully updated for {0}.",
  },
  api: {
    services: {
      devices: {
        terms:
          "Congratulations! It is now possible to connect your Verisure alarm to Safeland. You can connect both active and cancelled Verisure alarms.\n\nSafeland connects your alarm to neighbourhood watch and saves you money yearly on alarm central and security guards.",
      },
      devicePackages: {
        folklarmet_desc:
          "An impressive home alarm kit that doesn’t cost the earth. The Sikkerthjem S6evo is great value for money and contains all the fundamental components that a modern home alarm system needs.",
        premiumlarmet_desc:
          "The price awarded alarm that impresses the most. High tech but at the same time user friendly. Read more at www.safe.land",
      },
      paymentPlans: {
        basic_infos: [
          "Fastest response on alarms",
          "Alert friends and neighbours",
          "Connect to your smart home",
        ],
        plus_infos: [
          "Connect service",
          "Certifierad alarm central 24/7",
          "Access to security guards",
        ],
      },
    },
  },
};
