import React, { useCallback, useEffect, useState } from "react";
import Localization from "../../localization";
import { ServicesApi } from "../../apis";
import { Text } from "../../components";
import { List } from "antd";

const OrderAlarm = () => {
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    ServicesApi.devicePackages().then(setPackages);
  }, []);

  const renderListItem = useCallback(
    ({ title, subTitle, desc, image, readMoreLink, orderLink }) => (
      <List.Item
        extra={<img style={{ width: "10em", marginLeft: "1em" }} src={image} />}
        actions={[
          <a
            href={readMoreLink}
            target="_blank"
            children={
              <Text
                type="base"
                weight="600"
                children={Localization.general.read_more}
              />
            }
          />,
          <a
            href={orderLink}
            target="_blank"
            children={
              <Text
                type="base"
                weight="600"
                children={Localization.general.order_it}
              />
            }
          />,
        ]}
        children={
          <List.Item.Meta
            description={desc}
            title={
              <div>
                <Text level={4} children={title} />
                <Text weight={"normal"} opacity={0.7} children={subTitle} />
              </div>
            }
          />
        }
      />
    ),
    []
  );

  return (
    <div>
      <div className="text-align-center">
        <Text level={5} children={Localization.services.alarm_pkg_subtitle} />
        <Text children={Localization.services.alarm_pkg_subtitle} />
      </div>
      <List
        bordered
        className="m-20-t"
        itemLayout="vertical"
        dataSource={packages}
        renderItem={renderListItem}
      />
    </div>
  );
};

export default OrderAlarm;
