import { Button, Form, Text } from "../../components";
import Localization from "../../localization";
import React, { useRef } from "react";
import { App, Divider } from "antd";
import { useAuth } from "../../hooks";

const fields = [
  {
    type: "email",
    name: ["data", "username"],
    placeholder: "name@example.com",
    label: Localization.general.email_address,
    rules: [
      {
        type: "email",
        required: true,
        message: Localization.feedback.invalid_email,
      },
    ],
  },
  {
    type: "password",
    name: ["data", "password"],
    label: Localization.general.password,
    placeholder: Localization.general.type_password,
    rules: [
      {
        min: 4,
        required: true,
        message: Localization.feedback.invalid_password,
      },
    ],
  },
];

const Login = ({
  onRegisterClick = () => {},
  onForgetPassClick = () => {},
  onLogin = async () => {},
}) => {
  const { message } = App.useApp();
  const { login } = useAuth();
  const formRef = useRef();

  return (
    <div>
      <Form fields={fields} ref={formRef} />
      <Text
        underline
        onClick={onForgetPassClick}
        className="text-align-end m-10-t cursor-pointer"
        children={Localization.general.forgot_password}
      />
      <Button
        type="primary"
        title={Localization.general.login}
        className="w-100 m-25-t"
        onClick={async () => {
          try {
            await formRef.current.validateFields();
            await login(formRef.current.getFieldValue("data"));
            await onLogin();
          } catch (error) {
            message.error(error.message);
          }
        }}
      />
      <Divider className="m-12-v" />
      <Text className="text-align-center">
        {Localization.login.footer + " "}
        <Text
          underline
          level="link"
          onClick={onRegisterClick}
          children={Localization.general.register}
        />
      </Text>
    </div>
  );
};

export default Login;
