import { Button, Text } from "../../../components";
import Localization from "../../../localization";
import { NominatedContacts } from "./contacts";
import { CustomerForm } from "./customer";
import { ActionTypes } from "./actions";
import styled from "styled-components";
import { Card, Flex } from "antd";
import React from "react";

const Styled = styled.div`
  .ant-card-head-title {
    color: ${(_) => _.theme.token.fontColors.secondary};
  }
  .ant-card-body {
    padding: 1em;
  }
  .ant-card-head {
    background: ${(_) => _.theme.token.colorPrimary};
  }
  .customer-form-item {
    width: 100%;
    border-radius: 0.5em;
    padding: 0.2em 0.5em;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
`;

const details = [
  {
    title: Localization.service_edit_alarm_operator.summary_detail_1_title,
    value: Localization.service_edit_alarm_operator.summary_detail_1_desc,
  },
  {
    title: Localization.service_edit_alarm_operator.summary_detail_2_title,
    value: Localization.service_edit_alarm_operator.summary_detail_2_desc,
  },
  {
    title: Localization.service_edit_alarm_operator.summary_detail_3_title,
    value: Localization.service_edit_alarm_operator.summary_detail_3_desc,
  },
];

const Summary = ({
  actions = [],
  customer = {},
  contacts = {},
  onDone = () => {},
  onBack = () => {},
}) => {
  return (
    <Styled>
      <Text
        weight="600"
        children={`${Localization.general.step} 4: ${"Summary"}`}
      />
      <Flex vertical className="m-10-v" gap={"1em"}>
        <Card title={Localization.service_edit_alarm_operator.step1_title}>
          <CustomerForm
            customer={customer}
            renderFormItem={({ label, value }) => (
              <div className="customer-form-item ">
                <Text weight={"600"} children={label} />
                <Text children={value || Localization.general.na} />
              </div>
            )}
          />
        </Card>
        <Card
          title={Localization.service_edit_alarm_operator.contacts_table_title}
          children={<NominatedContacts contacts={contacts} />}
        />
        <Card title={Localization.service_edit_alarm_operator.actions_title}>
          <ActionTypes
            actions={actions}
            renderActionType={(_) => (
              <div>
                <Text level={5} children={_.title} />
                <Text children={_.value} />
              </div>
            )}
          />
        </Card>
      </Flex>
      <Flex
        vertical
        gap={"1em"}
        children={React.Children.toArray(
          details.map((_) => (
            <div>
              <Text level={5} children={_.title} />
              <Text children={_.value} />
            </div>
          ))
        )}
      />
      <Flex gap={"1em"} className="m-40-t">
        <Button
          className="w-100"
          onClick={() => onBack()}
          title={Localization.general.back}
        />
        <Button
          title={Localization.general.confirm}
          onClick={() => onDone()}
          className="w-100"
          type="primary"
        />
      </Flex>
    </Styled>
  );
};

export default Summary;
