import { Button, Container, FSLoader, Modal, Text } from "../../components";
import EditLocationDetails from "./editLocationDetails";
import EditAlarmConnection from "./editAlarmConnection";
import { Alert, App, Card, Flex, Progress, Result, theme } from "antd";
import { useStripe } from "@stripe/react-stripe-js";
import EditAlarmOperator from "./editAlarmOperator";
import LocationDetails from "./locationDetails";
import EditPaymentInfo from "./editPaymentInfo";
import AlarmConnection from "./alarmConnection";
import Localization from "../../localization";
import { useApi, useAuth } from "../../hooks";
import AlarmOperator from "./alarmOperator";
import { useParams } from "react-router";
import { ServicesApi } from "../../apis";
import PaymentInfo from "./paymentInfo";
import GroupsInfo from "./groupsInfo";
import EditGroups from "./editGroups";
import { format } from "date-fns";
import Collapse from "./collapse";
import React, {
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

export const ServiceContext = createContext({
  group: {},
  device: {},
  paymentInfo: {},
  isConfigured: false,
  isConnectPlus: false,
  refresh: async () => {},
});

const Connect = () => {
  const [loading, setLoading] = useState(false);
  const { uuid } = useParams();
  const [data, setData] = useState({ device: {}, paymentInfo: {} });
  const { execute } = useApi();
  const editLocationModalRef = useRef();
  const editAlarmConnectionModalRef = useRef();
  const editAlarmOperatorModalRef = useRef();
  const editGroupsModalRef = useRef();
  const editPaymentInfoModalRef = useRef();
  const [error, setError] = useState();
  const { token } = theme.useToken();
  const { modal } = App.useApp();
  const stripe = useStripe();
  const { user } = useAuth();

  const isConnectPlus = data.name === "connect-plus-se";
  const progress = Object.keys(data?.configuration?.progress ?? {}).length;
  const isConfigured = data.state !== "unconfigured";
  const unConfiguredItems = [
    {
      id: "location",
      emptyBtnClick: () =>
        editLocationModalRef.current.open({ progress: "location" }),
      title: Localization.service.step_1_title,
      emptyTitle: Localization.service.step_1_empty_title,
      emptyBtnTitle: Localization.service.step_1_empty_btn,
      children: (
        <LocationDetails
          onEditClick={() => editLocationModalRef.current.open()}
        />
      ),
    },
    {
      id: "arc",
      emptyBtnClick: () =>
        editAlarmConnectionModalRef.current.open({ progress: "arc" }),
      title: Localization.service.step_2_title,
      emptyTitle: Localization.service.step_2_empty_title,
      emptyBtnTitle: Localization.service.step_2_empty_btn,
      children: (
        <AlarmConnection
          onEditClick={() => editAlarmConnectionModalRef.current.open()}
        />
      ),
    },
    {
      id: "alarm",
      emptyBtnClick: () =>
        editAlarmOperatorModalRef.current.open({ progress: "alarm" }),
      title: Localization.service.step_3_title,
      emptyTitle: Localization.service.step_3_empty_title,
      emptyBtnTitle: Localization.service.step_3_empty_btn,

      children: (
        <AlarmOperator
          onEditClick={() => editAlarmOperatorModalRef.current.open()}
        />
      ),
    },
    {
      id: "collaborators",
      emptyBtnClick: () =>
        editGroupsModalRef.current.open({ progress: "collaborators" }),
      title: Localization.service.step_4_title,
      emptyTitle: Localization.service.step_4_empty_title,
      emptyBtnTitle: Localization.service.step_4_empty_btn,
      children: (
        <GroupsInfo onEditClick={() => editGroupsModalRef.current.open()} />
      ),
    },
  ].filter((_, i) => isConnectPlus || i !== 2);

  useEffect(() => {
    getService();
  }, []);

  const getService = useCallback(
    async (shouldLoad = true, { progress = "" } = {}) => {
      try {
        const res = await execute(
          async () => {
            if (!!progress)
              await ServicesApi.updateProgress(uuid, {
                ...(data?.configuration?.progress ?? {}),
                [progress]: true,
              });
            return await ServicesApi.service(uuid);
          },
          {
            showError: false,
            setLoading: (load) => setLoading(load && shouldLoad),
          }
        );
        res.device = res.configuration.devices[0];
        res.device.distribution = res.device.distributions[0];
        const alarm = await ServicesApi.device(res.device.type);
        res.device.alarm = alarm;
        if (!!progress) {
          const index = unConfiguredItems.findIndex((_) => _.id === progress);
          if (index !== unConfiguredItems.length - 1) {
            document
              .getElementById(unConfiguredItems[index + 1].id)
              .scrollIntoView({
                behavior: "smooth",
              });
          }
        }
        setData(res);
      } catch (error) {
        if (!!error)
          setError({
            status: error.status,
            title: error.status,
            subTitle: error.message,
          });
      }
    },
    [uuid, data]
  );

  const confirmCardSetup = ({ secret, card }) =>
    new Promise(async (resolve, reject) => {
      try {
        const { error } = await stripe.confirmCardSetup(secret, {
          payment_method: {
            card,
            billing_details: { name: user.alias },
          },
        });
        if (!!error) reject({ message: error.message });
        else setTimeout(() => resolve(), 3000);
      } catch (error) {
        reject(error);
      }
    });

  const renderUnConfiguredItem = useCallback(
    (_, index) => {
      const done = index < progress;
      const disable = index > progress;
      return (
        <div id={_.id}>
          <Collapse
            done={done}
            disable={disable}
            panelStyle={"padding: 0 !important"}
            active={Math.floor(progress) === index}
            title={`${Localization.general.step} ${index + 1}: ${_.title}`}
          >
            {done ? (
              _.children
            ) : (
              <Flex vertical align="center" justify="center" className="p-30">
                <Text align="center" children={_.emptyTitle} />
                <Button
                  type="primary"
                  title={_.emptyBtnTitle}
                  className="m-10-t w-50"
                  onClick={_.emptyBtnClick}
                />
              </Flex>
            )}
          </Collapse>
        </div>
      );
    },
    [progress]
  );

  const renderConfiguredState = useCallback(() => {
    return (
      <Flex vertical gap={"1em"}>
        {data?.state === "error" && (
          <Alert
            showIcon
            type="warning"
            message={Localization.service.error_title}
            description={Localization.service.error_desc}
          />
        )}
        <LocationDetails
          title={Localization.service.card_1_title}
          onEditClick={() => editLocationModalRef.current.open()}
        />
        <AlarmConnection
          title={Localization.service.card_2_title}
          onEditClick={() => editAlarmConnectionModalRef.current.open()}
        />
        {isConnectPlus && (
          <AlarmOperator
            title={Localization.service.card_3_title}
            onEditClick={() => editAlarmOperatorModalRef.current.open()}
          />
        )}
        <GroupsInfo onEditClick={() => editGroupsModalRef.current.open()} />
        <PaymentInfo
          onUpdate={() => editPaymentInfoModalRef.current.open()}
          onCancel={() => {
            modal.confirm({
              title: Localization.service.edit_payment_info_del_title,
              okText: Localization.service.edit_payment_info_del_btn,
              content: Localization.formatString(
                Localization.service.edit_payment_info_del_desc,
                format(data.paymentInfo?.nextPayment ?? new Date(), "P")
              ),
              cancelText: Localization.general.undo,
              onOk: async () => {
                await ServicesApi.delSubscription(uuid);
                await getService(false);
              },
            });
          }}
        />
      </Flex>
    );
  }, [isConnectPlus, data]);

  const renderUnConfiguredState = useCallback(() => {
    const percent = ((progress / (isConnectPlus ? 4 : 3)) * 100).toFixed(1);
    return (
      <Flex vertical gap={"1em"}>
        <Card title={Localization.service.unconf_title}>
          <Text className="m-10-b" children={Localization.service.unconf_p1} />
          <Text children={Localization.service.unconf_p2} />
          <Progress percent={percent} />
        </Card>
        {React.Children.toArray(unConfiguredItems.map(renderUnConfiguredItem))}
        {percent >= 100 && (
          <Button
            type="primary"
            shape="default"
            title={Localization.general.done}
            style={{ background: token.fontColors.success }}
            onClick={() => getService(false)}
          />
        )}
      </Flex>
    );
  }, [isConnectPlus, progress]);

  if (loading) return <FSLoader />;
  if (!!error) return <Result {...error} />;
  return (
    <ServiceContext.Provider
      value={{
        isConfigured,
        isConnectPlus,
        group: data.group,
        device: data.device,
        refresh: getService,
        paymentInfo: data.paymentInfo,
      }}
    >
      <Container>
        {!isConfigured ? renderUnConfiguredState() : renderConfiguredState()}
      </Container>
      <Modal
        ref={editLocationModalRef}
        title={Localization.service.edit_loc_title}
        child={({ progress = "" } = {}) => (
          <EditLocationDetails
            onUpdate={async () => {
              await getService(false, { progress });
              editLocationModalRef.current.close("");
            }}
          />
        )}
      />
      <Modal
        ref={editAlarmConnectionModalRef}
        title={Localization.service.edit_alarm_connection_title}
        child={({ progress = "" } = {}) => (
          <EditAlarmConnection
            onDone={async () => {
              await getService(false, { progress });
              editAlarmConnectionModalRef.current.close();
            }}
          />
        )}
      />
      <Modal
        ref={editAlarmOperatorModalRef}
        title={Localization.service.card_3_title}
        child={({ progress = "" } = {}) => (
          <EditAlarmOperator
            onDone={async () => {
              await getService(false, { progress });
              editAlarmOperatorModalRef.current.close();
            }}
          />
        )}
      />
      <Modal
        ref={editGroupsModalRef}
        title={Localization.general.collaborate}
        child={({ progress } = {}) => <EditGroups progress={progress} />}
      />
      <Modal
        width={550}
        ref={editPaymentInfoModalRef}
        title={Localization.service.payment_info_modal_title}
        child={() => (
          <EditPaymentInfo
            onDone={async (card) => {
              await execute(async () => {
                const { secret } = await ServicesApi.subscriptionIntent(uuid, {
                  service: data.name,
                  name: data.paymentInfo.paymentPlan.name,
                });
                await confirmCardSetup({ secret, card });
              });
              await getService(false);
              editPaymentInfoModalRef.current.close();
            }}
          />
        )}
      />
    </ServiceContext.Provider>
  );
};

export default Connect;
