import { Button, Text } from "../../components";
import Localization from "../../localization";
import React, { useContext } from "react";
import { ServiceContext } from ".";
import { Card, Flex } from "antd";

const AlarmOperator = ({ title = "", onEditClick = () => {} }) => {
  const { device } = useContext(ServiceContext);
  return (
    <Card title={title} className="border-transparent">
      <Flex gap={"1.5em"}>
        <img
          src="https://testapp.safe.land/img/services/connect-homes/alarms/westra_security_front_logo.svg"
          style={{ width: "40%" }}
        />
        <div>
          <Text
            level={3}
            children={Localization.service.alarm_operator_title}
          />
          <Text
            weight={500}
            type={device.arcConfirmed ? "success" : "destructive"}
            children={`${Localization.general.status}: ${
              device.arcConfirmed
                ? Localization.general.good
                : Localization.service.alarm_operator_status_error
            }`}
          />
        </div>
      </Flex>
      <Button
        ghost
        type="primary"
        onClick={onEditClick}
        className="w-100 m-20-t"
        title={Localization.service.card_3_btn}
      />
    </Card>
  );
};

export default AlarmOperator;
