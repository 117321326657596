import { ContentLoader, Container, Modal, Text } from "../../components";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Flex, QRCode, Result } from "antd";
import Localization from "../../localization";
import { useApi, useAuth } from "../../hooks";
import InvalidLicense from "./invalidLicense";
import SuccessModal from "./successModal";
import { ServicesApi } from "../../apis";
import { Tools } from "../../utils";
import Actions from "./actions";
import UnAuth from "./unAuth";

const Activate = () => {
  const [loading, setLoading] = useState(false);
  const activationSuccessModalRef = useRef();
  const { isAuthenticated } = useAuth();
  const [data, setData] = useState({});
  const { uuid = "" } = useParams();
  const navigate = useNavigate();
  const { execute } = useApi();

  const labels = [
    {
      name: Localization.general.service_desc,
      value: data.serviceDescription,
    },
    {
      name: Localization.general.total_value,
      value: Tools.toCurrency(data.amount),
    },
    {
      name: Localization.general.activation_code,
      value: Tools.maskLicenseKey(data.licenseKey),
    },
  ];

  useEffect(() => {
    if (isAuthenticated) getData(uuid);
  }, [uuid, isAuthenticated]);

  const getData = useCallback(
    async (id, { loading = true, showError = false } = {}) => {
      try {
        const data = await execute(() => ServicesApi.license(id), {
          setLoading: (state) => setLoading(state && loading),
          showError,
        });
        setData(data);
      } catch (error) {}
    },
    [uuid]
  );

  if (!isAuthenticated) return <UnAuth />;
  else if (loading)
    return (
      <Container>
        <ContentLoader skeletons={[{ height: 300 }, { height: 60 }]} />;
      </Container>
    );
  else if (!data.licenseKey)
    return (
      <Container>
        <InvalidLicense
          onDone={(id) => getData(id, { loading: false, showError: true })}
        />
      </Container>
    );
  return (
    <Container>
      <Card
        title={Localization.activate.header_title}
        actions={[
          <Actions
            activated={data.activated}
            onActivate={async () => {
              const { id } = await execute(() =>
                ServicesApi.activateLicense(data.licenseKey)
              );
              activationSuccessModalRef.current.open({ id });
            }}
          />,
        ]}
      >
        <Text
          align="center"
          level={3}
          children={Localization.general.safeland_connect}
        />
        <Text
          align="center"
          type="gray"
          children={`${1} ${Localization.general.year} ${
            Localization.general.subscription
          }`}
        />
        <Flex className="m-15-t" justify="space-between">
          <Flex
            vertical
            gap={"1em"}
            children={React.Children.toArray(
              labels.map(({ name, value }) => (
                <div>
                  <Text type="gray" children={name} />
                  <Text weight="500" type="tertiary" children={value} />
                </div>
              ))
            )}
          />
          <QRCode value={window.location.href} size={150} />
        </Flex>
      </Card>
      <Modal
        closeable={false}
        ref={activationSuccessModalRef}
        child={({ id }) => (
          <SuccessModal
            id={id}
            onDone={() => {
              navigate(`/service/${id}`);
            }}
          />
        )}
      />
    </Container>
  );
};

export default Activate;
