const auth = {
  SET_USER: "SET_USER",
  LOGOUT: "LOGOUT",
};

const general = {
  SET_IS_GENERAL_MOBILE_APP: "SET_IS_GENERAL_MOBILE_APP",
};

const ContextType = { auth, general };

export default ContextType;
