import Localization from "../../../localization";
import { Text } from "../../../components";
import { Constants } from "../../../utils";
import React, { useContext } from "react";
import { ServiceContext } from "..";
import Copyable from "../copyable";
import { Flex } from "antd";

const Webhook = () => {
  const { device } = useContext(ServiceContext);
  return (
    <div>
      <Flex gap={"1em"} align="center">
        {!!device?.alarm?.image && (
          <img src={device?.alarm.image} style={{ width: "7em" }} />
        )}
        <div>
          <Text level={4} children={device?.alarm?.name} />
          <Text children={Localization.service_webhook.desc_1} />
        </div>
      </Flex>
      <Copyable
        className="m-20-t"
        text={`${Constants.Project.alarmWebhookUrl}/${device.identifier}`}
      />
      <Text className="m-15-t" children={Localization.service_webhook.desc_2} />
    </div>
  );
};

export default Webhook;
