import { Button, MapView, Text } from "../../components";
import Localization from "../../localization";
import React, { useContext } from "react";
import { ServiceContext } from ".";
import { Card } from "antd";

const LocationDetails = ({ title = "", onEditClick = () => {} }) => {
  const { device } = useContext(ServiceContext);

  const center = {
    lat: device?.location?.latitude ?? 0,
    lng: device?.location?.longitude ?? 0,
  };

  return (
    <Card
      title={title}
      classNames={{ body: "p-0" }}
      className="border-transparent"
    >
      <MapView
        zoom={18}
        center={center}
        style={{ height: "200px" }}
        markers={[{ position: center }]}
        options={{
          disableDefaultUI: true,
          disableDoubleClickZoom: true,
          scaleControl: false,
          gestureHandling: "none",
          scrollwheel: false,
        }}
      />
      <div className="p-15">
        {!!device?.name && <Text level={4} children={device.name} />}
        {!!device?.distribution?.message && (
          <Text children={device.distribution.message} />
        )}
        <Button
          ghost
          type="primary"
          onClick={onEditClick}
          className="w-100 m-10-t"
          title={Localization.service.card_1_btn}
        />
      </div>
    </Card>
  );
};

export default LocationDetails;
