import { CardElement, useElements } from "@stripe/react-stripe-js";
import React, { useState } from "react";
import Localization from "../../localization";
import { Button } from "../../components";

const EditPaymentInfo = ({ onDone = async () => {} }) => {
  const [valid, setValid] = useState(false);
  const element = useElements();

  return (
    <div>
      <CardElement
        onChange={(_) => setValid(_.complete)}
        options={{
          disableLink: true,
          hidePostalCode: true,
        }}
      />
      <Button
        type="primary"
        disabled={!valid}
        className="w-100 m-20-t"
        title={Localization.general.confirm}
        onClick={async () => onDone(element.getElement(CardElement))}
      />
    </div>
  );
};

export default EditPaymentInfo;
