import { Checkbox, Dropdown, Divider, Alert, Flex, List, App } from "antd";
import { Button, ContentLoader, Modal, Text } from "../../../components";
import { EventApi, GroupApi, ServicesApi } from "../../../apis";
import Localization from "../../../localization";
import { useApi, useAuth } from "../../../hooks";
import CreateGroups from "./createGroups";
import { useParams } from "react-router";
import JsonView from "react18-json-view";
import "react18-json-view/src/style.css";
import { ServiceContext } from "..";
import Webhook from "./webhook";
import Invite from "./invite";
import Groups from "./groups";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  SettingOutlined,
  DeleteOutlined,
  EditOutlined,
  ApiOutlined,
} from "@ant-design/icons";

const signalOptions = [
  { title: Localization.general.burglary, value: "burglary" },
  { title: Localization.general.fire, value: "fire" },
  { title: Localization.general.emergency, value: "emergency" },
  { title: Localization.general.flood, value: "flood" },
];

const EditGroups = ({ progress = "" }) => {
  const { device, group, refresh, isConfigured } = useContext(ServiceContext);
  const [signals, setSignals] = useState(device.distribution.signals ?? []);
  const [loading, setLoading] = useState(false);
  const [invites, setInvites] = useState([]);
  const [groups, setGroups] = useState([]);
  const webhookModalRef = useRef();
  const inviteModalRef = useRef();
  const groupsModalRef = useRef();
  const createGroupsModalRef = useRef();
  const { uuid } = useParams();
  const { execute } = useApi();
  const { modal } = App.useApp();
  const { user } = useAuth();

  useEffect(() => {
    getData();
  }, [group?.id]);

  const getData = async () => {
    try {
      const { invites, groups } = await execute(
        async () => {
          const invites = !group?.id ? [] : await GroupApi.invites(group.id);
          const groups = await GroupApi.groups();
          return { invites, groups };
        },
        { setLoading }
      );
      setInvites(invites);
      setGroups(groups);
    } catch (error) {}
  };

  const sendInvites = async (emails) => {
    await execute(() => GroupApi.sendInvites(group?.id, emails));
    await getData();
    inviteModalRef.current.close();
  };

  const refreshService = async () => {
    await refresh(false, { progress });
    if (!isConfigured && !!progress)
      await execute(() => ServicesApi.configured(uuid));
  };

  const updateDistribution = async (options = {}) => {
    await execute(() =>
      EventApi.update(device.id, {
        ...device.distribution,
        ...options,
      })
    );
    await refreshService();
    groupsModalRef.current.close();
    webhookModalRef.current.close();
  };

  const renderCard = useCallback(
    ({ title = "", desc = "", footer = "", button = {} }) => {
      return (
        <React.Fragment>
          <Text
            level={5}
            type="base"
            align="center"
            className="m-15-b"
            children={title}
          />
          <Text children={desc} />
          <Button ghost type="primary" className="w-100 m-10-t" {...button} />
          {!!footer && <Text italic className="m-10-t" children={footer} />}
        </React.Fragment>
      );
    },
    []
  );

  const renderInvite = useCallback(() => {
    return renderCard({
      title:
        group?.name ??
        Localization.formatString(
          Localization.service_edit_collaborators.invite_title_placeholder,
          user.alias
        ),
      desc: Localization.service_edit_collaborators.invite_desc,
      button: !group
        ? {
            onClick: () => createGroupsModalRef.current.open(),
            title: Localization.service_edit_collaborators.invite_btn_2,
          }
        : {
            onClick: () => inviteModalRef.current.open(),
            title: Localization.service_edit_collaborators.invite_btn,
          },
      footer:
        !!group &&
        (!invites.length
          ? Localization.service_edit_collaborators.invite_footer_placeholder
          : Localization.formatString(
              Localization.service_edit_collaborators.invite_footer,
              invites.length
            )),
    });
  }, [group]);

  const renderWebhook = useCallback(() => {
    const { webhook } = device?.distribution ?? {};
    const items = [
      {
        icon: <ApiOutlined />,
        label: Localization.general.test,
        onClick: () =>
          modal.confirm({
            okText: Localization.general.send,
            title: Localization.service_edit_collaborators.webhook_test_title,
            content: Localization.service_edit_collaborators.webhook_test_desc,
            onOk: async () => {
              await execute(() => EventApi.testWebhook(uuid));
              await refreshService();
            },
          }),
      },
      {
        icon: <EditOutlined />,
        label: Localization.general.edit,
        onClick: () => webhookModalRef.current.open({ edit: true }),
      },
      {
        danger: true,
        icon: <DeleteOutlined />,
        label: Localization.general.delete,
        onClick: () =>
          modal.confirm({
            okText: Localization.general.yes,
            title: Localization.service_edit_collaborators.webhook_del_title,
            content: Localization.service_edit_collaborators.webhook_del_desc,
            onOk: () => updateDistribution({ webhooks: [] }),
          }),
      },
    ];

    if (!webhook)
      return (
        <Text
          type="base"
          weight={"600"}
          className="m-5-t cursor-pointer"
          onClick={() => webhookModalRef.current.open({})}
          children={Localization.service_edit_collaborators.hook_btn}
        />
      );
    return (
      <React.Fragment>
        <List
          bordered
          className="m-10-t"
          dataSource={[{}]}
          renderItem={() => (
            <Flex className="p-10" justify="space-between" gap={"1em"}>
              <div>
                <Text>
                  <Text
                    weight={"600"}
                    level={"text"}
                    children={webhook.method}
                  />
                  {": " + webhook.url}
                </Text>
                {!!webhook.body && (
                  <JsonView className="m-5-t" src={JSON.parse(webhook.body)} />
                )}
              </div>
              <Dropdown menu={{ items }}>
                <Text level={5} type="base" children={<SettingOutlined />} />
              </Dropdown>
            </Flex>
          )}
        />
        {!!webhook.log && (
          <Alert type="warning" className="m-10-t" message={webhook.log} />
        )}
      </React.Fragment>
    );
  }, [device.distribution.webhook]);

  const renderTriggers = useCallback(() => {
    return (
      <React.Fragment>
        <Text
          level={4}
          children={Localization.service_edit_alarm_operator.actions_title}
        />
        <Flex
          vertical
          gap={".3em"}
          className="m-10-t"
          children={React.Children.toArray(
            signalOptions.map((_) => (
              <Checkbox
                children={_.title}
                checked={signals.includes(_.value)}
                onChange={({ target }) => {
                  const items = target.checked
                    ? [...signals, _.value]
                    : signals.filter((signal) => signal !== _.value);
                  updateDistribution({ signals: items });
                  setSignals(items);
                }}
              />
            ))
          )}
        />
        <Divider className="m-15-v" />
      </React.Fragment>
    );
  }, [signals]);

  if (loading)
    return (
      <ContentLoader
        align="center"
        skeletons={[
          { height: "3em" },
          { width: "30%", size: "small" },
          { height: "4em", width: "50%" },
          { width: "30%", size: "small" },
          { width: "60%", top: "2em" },
          { height: "6em" },
          { width: "60%", top: "2em" },
          { height: "6em" },
        ]}
      />
    );
  return (
    <div>
      <Text
        weight={"600"}
        children={Localization.service_edit_collaborators.desc}
      />
      <Flex vertical align="center" className="m-20-t">
        <Text children={Localization.service_edit_collaborators.count_desc_1} />
        <Text
          level={3}
          align="center"
          children={Localization.formatString(
            Localization.service.card_4_cont_title,
            device.distribution?.recipients?.nrOfRecipients ?? "",
            device.distribution?.recipients?.groups?.length ?? 0
          )}
        />
        <Text children={Localization.service_edit_collaborators.count_desc_2} />
      </Flex>
      <Divider className="m-15-v" />
      {renderInvite()}
      <Divider className="m-15-v" />
      {!groups.length ? (
        <React.Fragment>
          <Text
            level={5}
            type="base"
            align="center"
            children={Localization.service_edit_collaborators.group_title}
          />
          <Text
            className="m-10-t"
            children={
              Localization.service_edit_collaborators.group_empty_desc_1
            }
          />
          <Text
            className="m-5-t"
            children={
              Localization.service_edit_collaborators.group_empty_desc_2
            }
          />
        </React.Fragment>
      ) : (
        renderCard({
          title: Localization.service_edit_collaborators.group_title,
          desc: Localization.service_edit_collaborators.group_desc,
          footer: Localization.service_edit_collaborators.group_footer,
          button: {
            title: Localization.service_edit_collaborators.group_btn,
            onClick: () => groupsModalRef.current.open(),
          },
        })
      )}
      <Divider className="m-15-v" />
      {[119, 120, 20001].includes(device.type) && renderTriggers()}
      <Text
        level={5}
        children={Localization.service_edit_collaborators.hook_title}
      />
      <Text children={Localization.service_edit_collaborators.hook_desc} />
      {renderWebhook()}
      <Modal
        ref={inviteModalRef}
        title={group?.name}
        child={() => <Invite onConfirm={sendInvites} />}
      />
      <Modal
        ref={groupsModalRef}
        title={Localization.service_edit_collaborators.group_modal_title}
        child={() => (
          <Groups
            groups={groups}
            onConfirm={(groups) => updateDistribution({ groups })}
          />
        )}
      />
      <Modal
        ref={webhookModalRef}
        title={Localization.service_edit_collaborators.webhook_modal_title}
        child={(data) => (
          <Webhook
            {...data}
            onConfirm={(webhook) => updateDistribution({ webhooks: [webhook] })}
          />
        )}
      />
      <Modal
        ref={createGroupsModalRef}
        title={Localization.service_edit_collaborators.new_group_modal_title}
        child={() => (
          <CreateGroups
            onSave={async (data) => {
              const group = await GroupApi.addGroup(data);
              await GroupApi.updatePrimaryGroup(uuid, { id: group.id });
              if (!groups.length) await updateDistribution({ groups: [group] });
              await refreshService();
              createGroupsModalRef.current.close();
            }}
            name={Localization.formatString(
              Localization.service_edit_collaborators.invite_title_placeholder,
              user.alias
            )}
          />
        )}
      />
    </div>
  );
};

export default EditGroups;
