import { AuthContext, ContextType, InitialValue } from "../context";
import React, { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Enums, Tools } from "../utils";
import { useGeneral } from "../hooks";
import { AuthApi } from "../apis";

const AuthProvider = ({ children }) => {
  const [value, setValue] = useState(InitialValue.auth);
  const { setIsSafelandMobileApp } = useGeneral();
  const [params, setParams] = useSearchParams();

  useEffect(() => {
    onAppStart();
  }, []);

  const dispatch = useCallback((key, payload) => {
    switch (key) {
      case ContextType.auth.SET_USER:
        Tools.storage.setItem(Enums.storageKeys.userUuid, payload?.userId);
        setValue((_) => ({ ..._, user: payload, isAuthenticated: true }));
        break;
      case ContextType.auth.LOGOUT: {
        Tools.storage.clear();
        setValue((_) => ({
          ..._,
          user: InitialValue.auth.user,
          isAuthenticated: false,
        }));
        break;
      }
    }
  }, []);

  const onAppStart = useCallback(async () => {
    const token = params.get("accessToken");
    if (!!token) {
      Tools.storage.setItem(Enums.storageKeys.token, token);
      setIsSafelandMobileApp(true);
    }
    try {
      if (!!Tools.storage.getItem(Enums.storageKeys.token)) {
        const user = await AuthApi.me();
        dispatch(ContextType.auth.SET_USER, user);
        if (!!token) {
          params.delete("accessToken");
          setParams(params);
        }
      } else {
        dispatch(ContextType.auth.LOGOUT);
      }
    } catch (error) {
      dispatch(ContextType.auth.LOGOUT);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ ...value, dispatch }} children={children} />
  );
};

export default AuthProvider;
