import { Button, Text } from "../../../components";
import Localization from "../../../localization";
import React, { useContext } from "react";
import { ServiceContext } from "..";
import Verisure from "./verisure";
import Webhook from "./webhook";
import Phone from "./phone";
import { Card } from "antd";
import Ajax from "./ajax";

const AlarmConnection = ({ title = "", onEditClick = () => {} }) => {
  const { device } = useContext(ServiceContext);
  const alarmConnectionError = {
    sms: {
      title: Localization.service.card_2_footer_sms_title,
      desc: Localization.service.card_2_footer_sms_desc,
    },
    ajax: {
      title: Localization.service.card_2_footer_ajax_title,
      desc: Localization.service.card_2_footer_ajax_desc,
    },
  }[device?.alarm?.integration];

  const renderAlarm = () => {
    switch (device?.alarm?.integration) {
      case "ajax":
        return <Ajax />;
      case "verisure":
        return <Verisure />;
      case "http":
        return <Webhook />;
      case "sms":
        return <Phone />;
      default:
        return <div />;
    }
  };

  return (
    <Card
      title={title}
      className="border-transparent"
      actions={[
        <div className="text-align-start p-20-h">
          <Text
            type="destructive"
            weight={500}
            children={alarmConnectionError?.title}
          />
          <Text type="destructive" children={alarmConnectionError?.desc} />
        </div>,
      ].slice(0, !!alarmConnectionError && !device?.identifier ? 1 : 0)}
    >
      {renderAlarm()}
      <Button
        ghost
        type="primary"
        onClick={onEditClick}
        className="w-100 m-20-t"
        title={Localization.service.card_2_btn}
      />
    </Card>
  );
};

export default AlarmConnection;
