import { Button, Form, Text } from "../../../components";
import Localization from "../../../localization";
import React, { useRef } from "react";

export const CustomerForm = React.forwardRef(
  ({ customer = {}, ...props }, ref) => {
    return (
      <Form
        ref={ref}
        {...props}
        fields={[
          [
            {
              value: customer.name,
              name: ["data", "name"],
              rules: [{ required: true }],
              label: Localization.general.your_full_name,
              placeholder: Localization.general.firstLastName,
            },
            {
              value: customer.email,
              name: ["data", "email"],
              rules: [{ required: true, type: "email" }],
              label: Localization.general.email_address,
              placeholder: "name@example.com",
            },
          ],
          {
            value: customer.phone,
            name: ["data", "phone"],
            rules: [{ required: true }],
            label: Localization.general.phone_number,
            placeholder: "0123 456 78 90",
          },
          [
            {
              value: customer.property,
              name: ["data", "property"],
              rules: [{ required: true }],
              label: Localization.general.home_name,
              placeholder: Localization.placeholder.home_name,
            },
            {
              value: customer.password,
              name: ["data", "password"],
              label: Localization.general.code_word,
              placeholder: Localization.general.voluntarily,
            },
          ],
          {
            value: customer.address,
            name: ["data", "address"],
            rules: [{ required: true }],
            label: Localization.general.home_address,
          },
          [
            {
              value: customer.postcode,
              name: ["data", "postcode"],
              rules: [{ required: true }],
              label: Localization.general.postcode,
            },
            {
              value: customer.city,
              name: ["data", "city"],
              rules: [{ required: true }],
              label: Localization.general.city,
            },
          ],
        ]}
      />
    );
  }
);

const Customer = ({ customer = {}, onNext = () => {} }) => {
  const formRef = useRef();
  return (
    <div>
      <Text
        weight="600"
        children={`${Localization.general.step} 1: ${Localization.service_edit_alarm_operator.step1_title}`}
      />
      <Text children={Localization.service_edit_alarm_operator.step1_desc} />
      <CustomerForm ref={formRef} customer={customer} className="m-10-t" />
      <Button
        type="primary"
        className="m-40-t w-100"
        title={Localization.general.save_and_cont}
        onClick={async () => {
          try {
            await formRef.current.validateFields();
            const { password, ...data } = formRef.current.getFieldValue("data");
            onNext(data, password);
          } catch (error) {}
        }}
      />
    </div>
  );
};

export default Customer;
