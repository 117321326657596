import { Button, Form, Text } from "../../components";
import Localization from "../../localization";
import { useApi, useAuth } from "../../hooks";
import React, { useRef } from "react";
import { App, Divider } from "antd";

const fields = [
  {
    name: ["data", "alias"],
    rules: [{ required: true }],
    label: Localization.general.name,
    placeholder: Localization.general.firstLastName,
  },
  {
    name: ["data", "email"],
    type: "email",
    placeholder: "name@example.com",
    label: Localization.general.email_address,
    rules: [{ type: "email", required: true }],
  },
  {
    type: "password",
    name: ["data", "password"],
    rules: [{ min: 4, required: true }],
    label: Localization.general.password,
    placeholder: Localization.general.type_password,
  },
  {
    type: "password",
    dependencies: ["password"],
    name: ["data", "confirm_password"],
    label: Localization.general.confirm_password,
    placeholder: Localization.general.type_password,
    rules: [
      { required: true, message: "" },
      ({ getFieldValue }) => ({
        validator(_, value) {
          return getFieldValue("data").password === value
            ? Promise.resolve()
            : Promise.reject(
                new Error(Localization.feedback.unmatched_password)
              );
        },
      }),
    ],
  },
  {
    face: "select",
    name: ["data", "countryCode"],
    rules: [{ required: true }],
    label: Localization.general.region,
    placeholder: Localization.general.select_region,
    options: [
      { label: Localization.general.sweden, value: "se" },
      { label: Localization.general.united_kingdom, value: "uk" },
    ],
  },
];

const Register = ({ onLoginClick = () => {}, onRegister = async () => {} }) => {
  const formRef = useRef();
  const { register } = useAuth();
  const { message } = App.useApp();
  const { execute } = useApi();

  return (
    <div>
      <Form fields={fields} ref={formRef} />
      <Button
        type="primary"
        className="w-100 m-25-t"
        title={Localization.general.register}
        onClick={async () => {
          await execute(
            async () => {
              await formRef.current.validateFields();
              await register(formRef.current.getFieldValue("data"));
              await onRegister();
            },
            { success: "Welcome to safeland connect" }
          );
        }}
      />
      <Divider className="m-12-v" />
      <Text className="text-align-center">
        {Localization.register.footer + " "}
        <Text
          underline
          level="link"
          onClick={onLoginClick}
          children={Localization.general.login}
        />
      </Text>
    </div>
  );
};

export default Register;
