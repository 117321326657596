import { Container } from "../../components";
import React, { useState } from "react";
import OrderAlarm from "./orderAlarm";
import AlarmList from "./alarmList";
import Details from "./details";
import { Card } from "antd";

const Services = () => {
  const [hasAlarm, setHasAlarm] = useState(true);

  return (
    <Container>
      <Details hasAlarm={hasAlarm} setHasAlarm={setHasAlarm} />
      <Card className="m-10-t">
        {hasAlarm ? <AlarmList /> : <OrderAlarm />}
      </Card>
    </Container>
  );
};

export default Services;
