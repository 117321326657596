import React from "react";
import Lottie from "react-lottie";
import { Animations } from "../../assets";

const FSLoader = ({ ...props }) => {
  return (
    <div
      style={{
        flex: 1,
        height: "100%",
        marginTop: "10%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      children={
        <Lottie
          height={200}
          width={200}
          options={{
            loop: true,
            autoplay: true,
            animationData: Animations.BubbleSpinner,
          }}
        />
      }
      {...props}
    />
  );
};

export default FSLoader;
