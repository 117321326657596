import { Container, Button, Text } from "../../components";
import Localization from "../../localization";
import { AuthLayout } from "../../layouts";
import React, { useRef } from "react";
import { Images } from "../../assets";
import { Card, Flex } from "antd";

const UnAuth = () => {
  const authLayoutRef = useRef();

  return (
    <Container>
      <Card
        title={Localization.activate.header_title}
        actions={[
          <div className="p-20-h">
            <Button
              type="primary"
              className="w-100"
              title={Localization.dashboard.card_1_btn}
              onClick={() => authLayoutRef.current.login.open()}
            />
          </div>,
        ]}
        children={
          <Flex vertical align="center" className="p-60-h">
            <img className="w-30" src={Images.LicenseActivation} />
            <Text
              level={4}
              align="center"
              className="m-20-t"
              children={Localization.activate.unauth_title}
            />
            <Text
              type="gray"
              align="center"
              children={Localization.activate.unauth_desc}
            />
          </Flex>
        }
      />
      <AuthLayout ref={authLayoutRef} />
    </Container>
  );
};

export default UnAuth;
