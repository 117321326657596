import { AlarmListLayout, AuthLayout, PaymentPlanLayout } from "../../layouts";
import { useNavigate, useSearchParams } from "react-router-dom";
import React, { useCallback, useEffect, useRef } from "react";
import { Button, Modal, Text } from "../../components";
import { useStripe } from "@stripe/react-stripe-js";
import Localization from "../../localization";
import { useApi, useAuth } from "../../hooks";
import { Animations } from "../../assets";
import { ServicesApi } from "../../apis";
import Lottie from "react-lottie";

const AlarmList = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { isAuthenticated } = useAuth();
  const successModalRef = useRef();
  const authLayoutRef = useRef();
  const planModalRef = useRef();
  const navigate = useNavigate();
  const { execute } = useApi();
  const stripe = useStripe();

  const type = searchParams.get("type");
  const sessionId = searchParams.get("session_id");

  useEffect(() => {
    if (!!type) planModalRef.current.open();
  }, [type]);

  useEffect(() => {
    if (searchParams.get("success") && !!sessionId)
      successModalRef.current.open();
  }, [searchParams, sessionId]);

  const subscribe = async ({
    service,
    name,
    authenticated = isAuthenticated,
  }) => {
    if (!authenticated) {
      authLayoutRef.current.login.open({ service, name });
      return;
    }
    try {
      const { sessionId } = await execute(() =>
        ServicesApi.checkout({
          name,
          type,
          service,
          successUrl:
            window.location.origin + window.location.pathname + `?success=true`,
          cancelUrl: window.location.href,
        })
      );
      await stripe.redirectToCheckout({ sessionId });
    } catch (error) {}
  };

  const configure = async () => {
    const { id } = await execute(() => ServicesApi.subscription(sessionId));
    navigate(`/service/${id}`);
  };

  const renderSuccessModal = useCallback(
    () => (
      <div>
        <Lottie
          height={100}
          width={100}
          options={{
            loop: false,
            autoplay: true,
            animationData: Animations.TickSuccess,
          }}
        />
        <Text
          level={4}
          align="center"
          className="m-10-b"
          children={Localization.services.success_title}
        />
        <Text align="center" children={Localization.services.success_desc} />
        <Button
          type="primary"
          shape="default"
          className="w-100 m-20-t"
          title={Localization.general.continue}
          onClick={configure}
        />
      </div>
    ),
    [searchParams]
  );

  return (
    <React.Fragment>
      <Text
        level={5}
        className="m-20-b text-align-center"
        children={Localization.services.alarm_list_title}
      />
      <AlarmListLayout
        onSelectItem={({ uuid }) => {
          searchParams.set("type", uuid);
          setSearchParams(searchParams);
        }}
      />
      <Modal
        width={550}
        ref={planModalRef}
        title={Localization.services.plan_modal_title}
        child={() => <PaymentPlanLayout onSubscribe={subscribe} />}
        afterClose={() => {
          searchParams.delete("type");
          setSearchParams(searchParams);
        }}
      />
      <Modal
        width={550}
        closeable={false}
        ref={successModalRef}
        child={renderSuccessModal}
      />
      <AuthLayout
        ref={authLayoutRef}
        onAuthComplete={(data) => subscribe({ ...data, authenticated: true })}
      />
    </React.Fragment>
  );
};

export default AlarmList;
