import { Button, Container, Form, Text } from "../../components";
import { useNavigate, useSearchParams } from "react-router-dom";
import Localization from "../../localization";
import React, { useRef } from "react";
import { useApi } from "../../hooks";
import { AuthApi } from "../../apis";
import { Card, Result } from "antd";

const ResetPassword = () => {
  const [params] = useSearchParams();
  const token = params.get("token");
  const email = params.get("email");
  const navigate = useNavigate();
  const { execute } = useApi();
  const formRef = useRef();

  const resetPassword = async () => {
    try {
      await execute(
        () =>
          AuthApi.resetPassword(token, {
            email,
            password: formRef.current.getFieldValue("password"),
          }),
        {
          success: Localization.formatString(
            Localization.reset_password.success_feedback,
            email
          ),
        }
      );
      navigate("/");
    } catch (error) {}
  };

  if (!email || !token)
    return (
      <Container>
        <Result
          status="404"
          title={Localization.reset_password.invalid_link_title}
          subTitle={Localization.reset_password.invalid_link_desc}
        />
      </Container>
    );
  return (
    <Container className="m-80-t">
      <Card title={Localization.reset_password.title}>
        <Text color="gray" className="m-15-b">
          {`${Localization.reset_password.desc} `}
          <Text level="text" children={email} />
          {" ."}
        </Text>
        <Form
          ref={formRef}
          fields={[
            {
              type: "password",
              name: ["password"],
              rules: [{ min: 4, required: true }],
              label: Localization.general.new_password,
              placeholder: Localization.general.type_password,
            },
            {
              type: "password",
              dependencies: ["password"],
              name: ["confirm_password"],
              label: Localization.general.confirm_password,
              placeholder: Localization.general.type_password,
              rules: [
                { required: true, message: "" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    return getFieldValue("password") === value
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error(Localization.feedback.unmatched_password)
                        );
                  },
                }),
              ],
            },
          ]}
        />
        <Button
          type="primary"
          className="w-100 m-40-t"
          title={Localization.reset_password.btn}
          onClick={async () => {
            try {
              await formRef.current.validateFields();
              await resetPassword();
            } catch (error) {}
          }}
        />
      </Card>
    </Container>
  );
};

export default ResetPassword;
