import { Container, FSLoader, ProCard, Text } from "../../components";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Localization from "../../localization";
import { ServicesApi } from "../../apis";
import Images from "../../assets/images";
import DeviceCard from "./deviceCard";
import { useApi } from "../../hooks";

const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const [devices, setDevices] = useState([]);
  const navigate = useNavigate();
  const { execute } = useApi();

  useEffect(() => {
    getServices();
  }, []);

  const getServices = useCallback(async () => {
    const data = await execute(ServicesApi.services, { setLoading });
    setDevices(
      data.map((_) => ({
        ..._.configuration.devices[0],
        uuid: _.id,
        state: _.state,
      }))
    );
  }, []);

  const renderDevice = useCallback(({ name, location, state, uuid }, index) => {
    return (
      <DeviceCard
        name={name}
        active={state !== "unconfigured"}
        className={`m-${!index ? 0 : 20}-t`}
        onClick={() => navigate("service/" + uuid)}
        center={{ lat: location.latitude, lng: location.longitude }}
      />
    );
  }, []);

  if (loading) return <FSLoader />;
  return (
    <React.Fragment>
      <Container>
        {React.Children.toArray(devices.map(renderDevice))}
        <div
          className={`m-15-${!!devices.length ? "v" : "b"} text-align-center`}
        >
          <Text
            level={5}
            tag="h3"
            children={Localization.dashboard.divider_title}
          />
          <Text
            children={
              !devices.length
                ? Localization.dashboard.divider_subtitle_empty
                : Localization.dashboard.divider_subtitle
            }
          />
        </div>
        <ProCard
          image={Images.Dashboard2}
          title={Localization.dashboard.card_2_title}
          desc={Localization.dashboard.card_2_desc}
          buttons={[
            {
              title: Localization.general.purchase,
              onClick: () => navigate("services"),
            },
            {
              ghost: true,
              title: Localization.general.learn_more,
              onClick: () =>
                window.open("https://www.safe.land/butik/connect/", "_blank"),
            },
          ]}
        />
      </Container>
    </React.Fragment>
  );
};

export default Dashboard;
