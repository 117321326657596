import Localization from "../localization";
import api from "./api";

const login = ({ username, password }) =>
  new Promise(async (resolve, reject) => {
    try {
      const data = await api.get("/auth", { params: { username, password } });
      resolve(data);
    } catch (error) {
      let message = Localization.feedback.something_wrong;
      if (error?.response?.status === 401)
        message = Localization.feedback.invalid_credentials;
      reject({ message });
    }
  });

const register = (payload = {}) =>
  new Promise(async (resolve, reject) => {
    try {
      const data = await api.post(`/rest/users`, {
        data: { regPlatform: 3, defaultLanguage: "sv", ...payload },
      });
      resolve(data);
    } catch (error) {
      if (error?.response?.status === 409)
        return reject({
          message: "User already exist!",
        });
      reject(error);
    }
  });

const me = () =>
  new Promise(async (resolve, reject) => {
    try {
      const data = await api.get(`/rest/users/me`);
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });

const meWithShortTermToken = (shortTermToken) =>
  new Promise(async (resolve, reject) => {
    try {
      const data = await api.get(`/rest/users/logged_in`, {
        params: { shortTermToken },
      });

      resolve(data);
    } catch (error) {
      reject({ error });
    }
  });

const forgotPassword = ({ email, captcha }) =>
  new Promise(async (resolve, reject) => {
    try {
      const data = await api.post(`/rest/v5/users/password_reset`, {
        data: { email, captcha },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });

const resetPassword = (token, data) =>
  new Promise(async (resolve, reject) => {
    try {
      await api.post(`/rest/users/password`, {
        data,
        params: { token, appid: 1 },
      });
      resolve();
    } catch (error) {
      let message = Localization.feedback.something_wrong;
      if (error?.response?.status === 404)
        message = Localization.feedback.reset_pass_exp_token;
      reject({ message });
    }
  });

export default {
  me,
  login,
  register,
  resetPassword,
  forgotPassword,
  meWithShortTermToken,
};
