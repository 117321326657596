import { Constants, Enums, Tools } from "../utils";
import axios from "axios";

const METHODS = [
  "get",
  "delete",
  "head",
  "options",
  "post",
  "put",
  "patch",
  "purge",
  "link",
  "unlink",
];

const handler = {
  get(_, method) {
    return (url = "", { unsecure, headers, ...options } = {}) =>
      new Promise(async (resolve, reject) => {
        if (!METHODS.includes(method)) reject("Invalid method");
        const accessToken = Tools.storage.getItem(Enums.storageKeys.token);
        if (!unsecure) {
          options.params = {
            ...(options?.params ?? {}),
            applicationPublicKey: Constants.Project.publicKey,
          };
        }
        try {
          let { data: body } = await axios({
            url,
            method,
            timeout: 6000,
            baseURL: Constants.Project.baseUrl,
            headers: {
              ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
              ...headers,
            },
            ...options,
          });

          if (!!body.error) return reject(body.error);
          resolve(body);
        } catch (error) {
          reject(error);
        }
      });
  },
};

export default new Proxy({}, handler);
