import { ContextType, GeneralContext, InitialValue } from "../context";
import React, { useCallback, useState } from "react";
import { Enums, Tools } from "../utils";

const GeneralProvider = ({ children }) => {
  const [value, setValue] = useState(InitialValue.general);

  const dispatch = useCallback((key, payload) => {
    switch (key) {
      case ContextType.general.SET_IS_GENERAL_MOBILE_APP:
        Tools.storage.setItem(Enums.storageKeys.isSafelandMobileApp, payload);
        setValue((_) => ({ ..._, isSafelandMobileApp: payload }));
        break;
    }
  }, []);

  return (
    <GeneralContext.Provider
      value={{ ...value, dispatch }}
      children={children}
    />
  );
};

export default GeneralProvider;
