import Localization from "../localization";
import api from "./api";

const hubs = () =>
  new Promise(async (resolve, reject) => {
    try {
      const data = await api.get(`/rest/ajax/hubs`);
      resolve(
        data.map((_) => ({
          image: "https://testapp.safe.land/img/connect/products/ajax/Hub.jpg",
          ..._,
        }))
      );
    } catch (error) {
      reject(error);
    }
  });

const user = () =>
  new Promise(async (resolve, reject) => {
    try {
      const data = await api.get(`/rest/ajax/user`);
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });

const login = ({ email, password }) =>
  new Promise(async (resolve, reject) => {
    try {
      const data = await api.post(`/rest/ajax/login`, {
        data: {
          credentialsKey: email,
          credentialsSecret: password,
        },
      });
      resolve(data);
    } catch (error) {
      if (error?.response?.status === 401)
        return reject({ message: Localization.feedback.invalid_credentials });
      reject(error);
    }
  });

export default { hubs, user, login };
