import { Col, Row } from "antd";
import React from "react";

const Container = ({ children, className, ...props }) => (
  <Row>
    <Col xs={1} md={2} xl={7} />
    <Col
      xs={22}
      md={20}
      xl={10}
      children={children}
      className={`m-20-v ${className}`}
      {...props}
    />
    <Col xs={1} md={2} xl={7} />
  </Row>
);

export default Container;
