import { Typography, theme } from "antd";
import React from "react";

const Text = ({
  color = "",
  children = "",
  tag = "p",
  type = "primary",
  level = 0,
  className = "",
  style = {},
  align = undefined,
  weight = undefined,
  opacity = undefined,
  ...props
}) => {
  const AntText = [1, 2, 3, 4, 5].includes(level)
    ? Typography.Title
    : level === "link"
    ? Typography.Link
    : level === "text"
    ? Typography.Text
    : Typography.Paragraph;
  const { token } = theme.useToken();
  return (
    <AntText
      level={level}
      children={children}
      className={`m-0 ${className}`}
      style={{
        opacity,
        textAlign: align,
        fontWeight: weight,
        color: color || token.fontColors[type],
        ...style,
      }}
      {...props}
    />
  );
};

export default Text;
