const Project = {
  baseUrl: "https://api.safe.land/",
  publicKey: "124b79acb8a7477ab9f1eca9128d6175",
  googleApiKey: "AIzaSyD4NDWyZ5OoCxzS2YCB81Fsk456t7vC4vk",
  reCAPTCHASitekey: "6Ld7ug4TAAAAANwINpM8tISk0L23n43iQAfEv1t4",
  alarmWebhookUrl: "https://api.safe.land/rest/alarm",
  stripeApiKey: "pk_live_mIepws7T02dYznJv5J9eJnVg",
  safelandPhoneNumber: "+46 766 86 12 19",
};

const Constants = { Project };

export default Constants;
