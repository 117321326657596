import { Enums, Tools } from "../utils";

const auth = {
  user: {},
  dispatch: () => {},
  isAuthenticated: undefined,
};

const general = {
  isSafelandMobileApp:
    Tools.storage.getItem(Enums.storageKeys.isSafelandMobileApp) ?? false,
};

const InitialValue = { auth, general };

export default InitialValue;
