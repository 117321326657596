import Localization from "../../../localization";
import { Text } from "../../../components";
import { Constants } from "../../../utils";
import React, { useContext } from "react";
import { ServiceContext } from "..";
import Copyable from "../copyable";

const Verisure = () => {
  const { device } = useContext(ServiceContext);

  const renderOrderList = ({ text, list = [] }, index = 0) => {
    return (
      <React.Fragment>
        <Text
          weight="600"
          className="m-14-t"
          children={`${index + 1}. ${text}`}
        />
        <ul
          children={React.Children.toArray(
            list.map((text) => (
              <li>
                <Text children={text} />
              </li>
            ))
          )}
        />
      </React.Fragment>
    );
  };
  return (
    <div>
      <Text children={Localization.service_verisure.desc} />
      {React.Children.toArray(
        [
          {
            text: Localization.service_verisure.step_1,
            list: [
              Localization.formatString(
                Localization.service_verisure.step_1_1,
                Constants.Project.safelandPhoneNumber
              ),
              Localization.service_verisure.step_1_2,
            ],
          },
          {
            text: Localization.service_verisure.step_2,
            list: [Localization.service_verisure.step_2_1],
          },
        ].map(renderOrderList)
      )}
      <Copyable text={`#${device.identifier}#`} className="m-20-t" />
    </div>
  );
};

export default Verisure;
