import { Button, Text } from "../../components";
import Styled from "./styled";
import { Flex } from "antd";
import React from "react";

const ProCard = ({
  image = "",
  title = "",
  desc = "",
  buttons = [],
  ...props
}) => {
  return (
    <Styled {...props} image={image}>
      <div className="content">
        <Text
          tag="h2"
          level={3}
          type="secondary"
          children={title.toUpperCase()}
        />
        <Text children={desc} type="secondary" />
        <Flex
          vertical
          gap={"1em"}
          className="m-15-t"
          children={React.Children.toArray(
            buttons.map((button) => <Button {...button} />)
          )}
        />
      </div>
    </Styled>
  );
};

export default ProCard;
