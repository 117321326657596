import { Button, Text } from "../../components";
import Localization from "../../localization";
import React, { useContext } from "react";
import { Enums } from "../../utils";
import { ServiceContext } from ".";
import { format } from "date-fns";
import { Card } from "antd";

const PaymentInfo = ({ onUpdate = () => {}, onCancel = () => {} }) => {
  const { paymentInfo, isConnectPlus } = useContext(ServiceContext);
  const isMonthly = paymentInfo?.paymentPlan?.name === "monthly";
  const isCanceled = paymentInfo.state === "cancelled";
  const type = paymentInfo?.paymentMethod?.type;

  return (
    <Card
      title={Localization.service.card_5_title}
      actions={[
        type === Enums.paymentType.activation && !isCanceled && (
          <Text
            type="destructive"
            className="text-align-start p-20-h"
            children={Localization.service.payment_activate_footer}
          />
        ),
        isCanceled && (
          <div className="text-align-start p-20-h">
            <Text
              weight={500}
              type="destructive"
              children={Localization.service.payment_info_canceled_footer_title}
            />
            <Text
              type="destructive"
              children={Localization.formatString(
                Localization.service.payment_info_canceled_footer_desc,
                format(paymentInfo?.nextPayment ?? new Date(), "yyyy-MM-dd")
              )}
            />
          </div>
        ),
      ].filter((_) => !!_)}
    >
      <Text
        level={3}
        children={
          isConnectPlus
            ? Localization.general.connect_plus
            : Localization.general.connect
        }
      />
      {isCanceled ? (
        <Text children={Localization.service.payment_info_canceled_desc} />
      ) : (
        <React.Fragment>
          <Text
            children={Localization.formatString(
              isMonthly
                ? Localization.service.monthly_pay
                : Localization.service.annually_pay,
              paymentInfo?.paymentPlan?.amount ?? 0,
              paymentInfo?.paymentPlan?.currency ?? "",
              format(paymentInfo?.nextPayment ?? new Date(), "yyyy-MM-dd")
            )}
          />
          {type === Enums.paymentType.invoice ? (
            <React.Fragment>
              <Text
                level={3}
                className="m-20-t"
                children={`${Localization.service.payment_invoice_title}:`}
              />
              <Text children={Localization.service.payment_invoice_desc} />
            </React.Fragment>
          ) : (
            <React.Fragment>
              {type !== Enums.paymentType.activation && (
                <React.Fragment>
                  <Text
                    level={3}
                    className="m-20-t"
                    children={`${Localization.general.payment_method}:`}
                  />
                  <Text children={paymentInfo?.paymentMethod?.name} />
                </React.Fragment>
              )}
              <Button
                type="primary"
                onClick={onUpdate}
                className="w-100 m-15-t"
                title={Localization.service.card_5_btn}
              />
              <Button
                danger
                type="primary"
                onClick={onCancel}
                className="w-100 m-10-t"
                title={Localization.service.card_5_btn_2}
              />
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </Card>
  );
};

export default PaymentInfo;
