import React, { useCallback, useContext, useState, useRef } from "react";
import { Button, Modal, Text } from "../../../components";
import { AlarmListLayout } from "../../../layouts";
import Localization from "../../../localization";
import { ServicesApi } from "../../../apis";
import { useApi } from "../../../hooks";
import { ServiceContext } from "../";
import Phone from "./phone";
import { Flex } from "antd";
import Ajax from "./ajax";

const EditAlarmConnection = ({ onDone = async () => {} }) => {
  const { device } = useContext(ServiceContext);
  const [alarm, setAlarm] = useState(device.alarm);
  const alarmConfigModalRef = useRef();
  const alarmListModalRef = useRef();
  const { execute } = useApi();

  const addAlarm = async () => {
    try {
      if (["ajax", "sms"].includes(alarm?.integration)) {
        alarmConfigModalRef.current.open();
      } else {
        await updateDevice({ type: alarm.uuid });
      }
    } catch (error) {}
  };

  const updateDevice = async (data) =>
    new Promise(async (resolve, reject) => {
      try {
        await execute(() => ServicesApi.updateDevice(device.id, data));
        await onDone();
        resolve();
      } catch (error) {
        reject();
      }
    });

  const onDoneConfig = async (identifier) => {
    try {
      await updateDevice({ type: alarm.uuid, identifier });
      alarmConfigModalRef.current.close();
    } catch (error) {}
  };

  const renderAlarmListModal = useCallback(() => {
    return (
      <div>
        <Text
          weight="500"
          align="center"
          children={Localization.service.edit_alarm_connection_alarms_desc_1}
        />
        <Text
          align="center"
          className="m-20-b"
          children={Localization.service.edit_alarm_connection_alarms_desc_2}
        />
        <AlarmListLayout
          onSelectItem={(alarm) => {
            setAlarm(alarm);
            alarmListModalRef.current.close();
          }}
        />
      </div>
    );
  }, []);

  const renderAlarmConfig = () => {
    switch (alarm?.integration) {
      case "ajax":
        return <Ajax onDone={onDoneConfig} />;
      case "sms":
        return <Phone onDone={onDoneConfig} />;
      default:
        return <div />;
    }
  };

  return (
    <Flex vertical align="center">
      <Text
        weight="500"
        align="center"
        children={Localization.service.edit_alarm_connection_desc_1}
      />
      <Text
        align="center"
        children={Localization.service.edit_alarm_connection_desc_2}
      />
      <Flex align="center" className="m-12-v" gap={"1em"}>
        {!!alarm?.image && (
          <img style={{ width: "6em", height: undefined }} src={alarm.image} />
        )}
        <div>
          <Text level={5} children={alarm?.name} />
          <Text children={alarm?.vendor} />
        </div>
      </Flex>
      <Button
        type="primary"
        className="w-100"
        onClick={() => addAlarm()}
        title={Localization.service.edit_alarm_connection_btn_1}
      />
      <Button
        className="w-100 m-12-t"
        onClick={() => alarmListModalRef.current.open()}
        title={Localization.service.edit_alarm_connection_btn_2}
      />
      <Modal
        ref={alarmListModalRef}
        child={renderAlarmListModal}
        title={Localization.service.edit_alarm_connection_title}
      />
      <Modal
        child={renderAlarmConfig}
        ref={alarmConfigModalRef}
        title={Localization.service.edit_alarm_connection_config_modal_title}
      />
    </Flex>
  );
};

export default EditAlarmConnection;
