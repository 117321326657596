import { Avatar, Checkbox, Divider, Flex, List } from "antd";
import React, { useContext, useState } from "react";
import { Button, Text } from "../../../components";
import Localization from "../../../localization";
import { Constants } from "../../../utils";
import { ServiceContext } from "..";
import {
  EyeInvisibleOutlined,
  GlobalOutlined,
  LockFilled,
} from "@ant-design/icons";

export const GroupTypes = [
  { id: 0, title: Localization.general.public, icon: GlobalOutlined },
  { id: 1, title: Localization.general.locked, icon: LockFilled },
  { id: 2, title: Localization.general.private, icon: EyeInvisibleOutlined },
];

const Groups = ({ groups = [], onConfirm = () => {} }) => {
  const { device } = useContext(ServiceContext);
  const [selectedGroups, setSelectedGroups] = useState(
    device.distribution.groups.map((_) => _.id)
  );

  return (
    <div>
      <Text
        children={Localization.service_edit_collaborators.group_modal_desc}
      />
      <List
        bordered
        className="m-10-t"
        dataSource={groups}
        renderItem={({ name, type, pictureRef, id, nrOfMembers }, index) => {
          const checkboxId = index + "checkbox";
          const { icon: Icon, title: typeName } =
            GroupTypes.find((_) => _.id === type) || {};
          return (
            <React.Fragment>
              <label htmlFor={checkboxId}>
                <Flex
                  justify="space-between"
                  align="start"
                  className="p-10 cursor-pointer"
                >
                  <Flex gap={".8em"}>
                    <Avatar
                      src={`${Constants.Project.baseUrl}${pictureRef}`}
                      size="large"
                    />
                    <div>
                      <Text level={5} children={name} />
                      <Text>
                        <Icon />
                        {` ${typeName} ${Localization.general.group.toLowerCase()} • ${nrOfMembers} ${
                          Localization.general.members
                        }`}
                      </Text>
                    </div>
                  </Flex>
                  <Checkbox
                    id={checkboxId}
                    checked={selectedGroups.includes(id)}
                    onChange={({ target }) =>
                      setSelectedGroups((_) =>
                        target.checked
                          ? [..._, id]
                          : _.filter((groupId) => groupId !== id)
                      )
                    }
                  />
                </Flex>
              </label>
              <Divider className="m-0" />
            </React.Fragment>
          );
        }}
      />
      <Button
        type="primary"
        className="m-20-t w-100"
        title={Localization.general.confirm}
        onClick={() =>
          onConfirm(groups.filter((_) => selectedGroups.includes(_.id)))
        }
      />
    </div>
  );
};

export default Groups;
