import { Button, Text } from "../../components";
import Localization from "../../localization";
import React, { useState } from "react";
import { Checkbox, Flex } from "antd";

const AlarmTerms = ({
  terms = "",
  onCancel = () => {},
  onContinue = () => {},
}) => {
  const [accepted, setAccepted] = useState(false);
  return (
    <div>
      <Text children={terms} />
      <Checkbox
        className="text-align-center p-20-v"
        children={Localization.general.accept_terms}
        onChange={(_) => setAccepted(_.target.checked)}
      />
      <Flex gap={"1em"} justify="center">
        <Button
          type="primary"
          disabled={!accepted}
          onClick={onContinue}
          title={Localization.general.continue}
        />
        <Button title={Localization.general.cancel} onClick={onCancel} />
      </Flex>
    </div>
  );
};

export default AlarmTerms;
