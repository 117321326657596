import { Container, ProCard } from "../../components";
import { useNavigate } from "react-router-dom";
import Localization from "../../localization";
import { AuthLayout } from "../../layouts";
import Images from "../../assets/images";
import React, { useRef } from "react";

const Authentication = () => {
  const authLayoutRef = useRef({});
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Container className="text-align-center">
        <ProCard
          className="m-30-b"
          image={Images.Dashboard1}
          title={Localization.dashboard.card_1_title}
          desc={Localization.dashboard.card_1_desc}
          buttons={[
            {
              title: Localization.dashboard.card_1_btn,
              onClick: () => authLayoutRef.current.login.open(),
            },
          ]}
        />
        <ProCard
          image={Images.Dashboard2}
          title={Localization.dashboard.card_2_title}
          desc={Localization.dashboard.card_2_desc}
          buttons={[
            {
              title: Localization.general.purchase,
              onClick: () => navigate("services"),
            },
            {
              ghost: true,
              title: Localization.general.learn_more,
              onClick: () =>
                window.open("https://www.safe.land/butik/connect/", "_blank"),
            },
          ]}
        />
      </Container>
      <AuthLayout ref={authLayoutRef} />
    </React.Fragment>
  );
};

export default Authentication;
