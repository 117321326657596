import styled from "styled-components";

const Styled = styled.div`
  overflow: hidden;
  border-radius: 1em;
  background-image: ${(_) => `url(${_.image})`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: #cccccc;
  .content {
    padding: 4em;
    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.4);
  }
`;

export default Styled;
