import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { Constants } from "../../utils";
import React from "react";

const MapView = ({
  center = { lat: 0, lng: 0 },
  style,
  className,
  markers = [],
  ...props
}) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: Constants.Project.googleApiKey,
  });

  return (
    <div
      style={style}
      className={className}
      children={
        isLoaded && (
          <GoogleMap
            center={center}
            mapContainerStyle={{
              width: "100%",
              height: "100%",
              overflow: "hidden",
              // borderRadius: "30px",
            }}
            {...props}
          >
            {React.Children.toArray(
              markers.map((marker) => <Marker {...marker} />)
            )}
          </GoogleMap>
        )
      }
    />
  );
};

export default MapView;
