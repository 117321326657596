import { Button, Text } from "../../components";
import Localization from "../../localization";
import { Animations } from "../../assets";
import Lottie from "react-lottie";
import React from "react";

const SuccessModal = ({ id, onDone = () => {} }) => {
  return (
    <div>
      <Lottie
        height={100}
        width={100}
        options={{
          loop: false,
          autoplay: true,
          animationData: Animations.TickSuccess,
        }}
      />
      <Text
        level={4}
        align="center"
        className="m-10-b"
        children={Localization.activate.success_title}
      />
      <Text align="center" children={Localization.activate.success_desc} />
      <Button
        type="primary"
        shape="default"
        onClick={onDone}
        className="w-100 m-20-t"
        title={Localization.activate.success_btn}
      />
    </div>
  );
};

export default SuccessModal;
