import { Route, Routes } from "react-router-dom";
import Localization from "../localization";
import { FSLoader } from "../components";
import { useAuth } from "../hooks";
import { Result } from "antd";
import React from "react";
import {
  Authentication,
  ResetPassword,
  Dashboard,
  Activate,
  Service,
  Services,
} from "../pages";

const AppRoute = [
  <Route path={"/"} Component={Dashboard} />,
  <Route path={"/service/:uuid"} Component={Service} />,
];

const AuthRoute = [<Route path={"/"} Component={Authentication} />];

const Router = () => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated === undefined) return <FSLoader />;
  return (
    <Routes>
      {isAuthenticated ? AppRoute : AuthRoute}
      <Route path={"/services"} Component={Services} />
      <Route path={"/activate"} Component={Activate} />
      <Route path={"/activate/:uuid"} Component={Activate} />
      <Route path={"/resetpassword"} Component={ResetPassword} />
      <Route
        path={"*"}
        element={
          <Result
            status="404"
            title={Localization.not_found.title}
            subTitle={Localization.not_found.desc}
          />
        }
      />
    </Routes>
  );
};

export default Router;
