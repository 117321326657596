import { Card, Divider, Flex, Row, Col } from "antd";
import { Text, Button } from "../../components";
import Localization from "../../localization";
import React from "react";

const descriptions = [
  Localization.services.info_1,
  Localization.services.info_2,
  Localization.services.info_3,
];

const Details = ({ hasAlarm = false, setHasAlarm = () => {} }) => {
  return (
    <Card>
      <Row gutter={1} justify="space-between">
        <Col xs={24} sm={24} lg={16}>
          <Text level={2} children={Localization.services.title} />
          <Text level={5} children={Localization.services.subtitle} />
          <ul
            className="m-12-t"
            children={React.Children.toArray(
              descriptions.map((desc) => (
                <li children={<Text children={desc} />} />
              ))
            )}
          />
        </Col>
        <Col xs={0} sm={0} lg={7}>
          <img
            className="w-100"
            src="https://testapp.safe.land/img/connect/safeland-connect-alarm.png"
          />
        </Col>
      </Row>
      <Divider />
      <div className="text-align-center">
        <Text
          className="m-12-b"
          children={Localization.services.alarm_conf_para}
        />
        <Flex gap="1em" justify="center">
          <Button
            className="p-40-h"
            title={Localization.general.yes}
            onClick={() => setHasAlarm(true)}
            type={hasAlarm ? "primary" : "default"}
          />
          <Button
            className="p-40-h"
            title={Localization.general.no}
            onClick={() => setHasAlarm(false)}
            type={!hasAlarm ? "primary" : "default"}
          />
        </Flex>
      </div>
    </Card>
  );
};

export default Details;
