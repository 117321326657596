import { useCallback } from "react";
import { App } from "antd";

const useApi = () => {
  const { message } = App.useApp();
  const execute = useCallback(
    (
      api = async () => {},
      { success = "", setLoading = () => {}, showError = true } = {}
    ) =>
      new Promise(async (resolve, reject) => {
        setLoading(true);
        try {
          const data = await api();
          if (!!success)
            message.success(
              typeof success === "function" ? success(data) : success
            );
          resolve(data);
        } catch (error) {
          if (!!error.message && showError) message.error(error.message);
          reject(error);
        } finally {
          setLoading(false);
        }
      }),
    []
  );

  return { execute };
};

export default useApi;
