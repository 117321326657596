import { Collapse, Flex } from "antd";
import styled from "styled-components";

const StyledCollapse = styled(Collapse)`
  .ant-collapse-content-box {
    ${(_) => _.panelStyle};
  }
`;

export { StyledCollapse };
