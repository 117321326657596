import { ContentLoader } from "../../../components";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ServicesApi } from "../../../apis";
import { useApi } from "../../../hooks";
import Customer from "./customer";
import Contacts from "./contacts";
import Actions from "./actions";
import Summary from "./summary";

const EditAlarmOperator = ({ onDone = async () => {} }) => {
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [arc, setArc] = useState({
    actions: [],
    contacts: [],
    customer: {},
  });
  const { uuid } = useParams();
  const { execute } = useApi();

  useEffect(() => {
    getArc();
  }, [uuid]);

  useEffect(() => {
    document.getElementById("header").scrollIntoView();
  }, [progress]);

  const getArc = async () => {
    try {
      const arc = await execute(() => ServicesApi.arc(uuid), { setLoading });
      if (!arc.actions?.length)
        arc.actions = [
          { type: "burglary", action: 4 },
          { type: "fire", action: 4 },
          { type: "fight", action: 0 },
          { type: "emergency", action: 4 },
          { type: "sabotage", action: 4 },
          { type: "tech", action: 0 },
        ];
      setArc(arc);
    } catch (error) {}
  };

  const updateArc = async () => {
    await execute(() => ServicesApi.updateArc(uuid, arc));
    await onDone();
  };

  if (loading)
    return (
      <ContentLoader
        skeletons={[
          { size: "small", className: "w-70" },
          { height: 50 },
          { size: "large", height: 300 },
          { size: "large", shape: "round", height: 40 },
        ]}
      />
    );
  return (
    <div>
      <div id="header" style={{ scrollMarginTop: "2em" }} />
      {
        [
          <Customer
            customer={{
              ...arc.customer,
              password: arc?.contacts[0]?.password ?? "",
            }}
            onNext={(customer, password) => {
              const data = arc;
              data.customer = customer;
              if (!data.contacts.length)
                data.contacts = [
                  {
                    authority: 5,
                    name: customer.name,
                    phone: customer.phone,
                    email: customer.email,
                  },
                ];
              data.contacts[0].password = password;
              setArc(data);
              setProgress(1);
            }}
          />,
          <Contacts
            contacts={arc.contacts}
            onBack={() => setProgress(0)}
            onNext={() => {
              setProgress(2);
            }}
            onAdd={(contact) => {
              setArc({ ...arc, contacts: [...arc.contacts, contact] });
            }}
            onEdit={(index, contact) => {
              arc.contacts[index] = contact;
              setArc({ ...arc, contacts: [...arc.contacts] });
            }}
            onRemove={(indexes = []) => {
              setArc({
                ...arc,
                contacts: arc.contacts.filter((_, i) => !indexes.includes(i)),
              });
            }}
          />,
          <Actions
            actions={arc.actions}
            onBack={() => setProgress(1)}
            onNext={() => setProgress(3)}
            onSelect={(type, index) => {
              const actions = [...arc.actions];
              actions[arc.actions.findIndex((_) => _.type === type)].action =
                index;
              setArc({ ...arc, actions });
            }}
          />,
          <Summary {...arc} onDone={updateArc} onBack={() => setProgress(2)} />,
        ][progress]
      }
    </div>
  );
};

export default EditAlarmOperator;
