import React, { useEffect, useState } from "react";
import { Collapse as AntCollapse } from "antd";
import { StyledCollapse } from "./styled";
import { Text } from "../../components";

const Collapse = ({
  children,
  title = "",
  panelStyle,
  done = false,
  active = false,
  disable = false,
} = {}) => {
  const [activeKey, setActiveKey] = useState([]);

  useEffect(() => {
    setActiveKey(active ? [1] : []);
  }, [active]);

  return (
    <StyledCollapse
      activeKey={activeKey}
      panelStyle={panelStyle}
      style={{ background: "white" }}
      collapsible={disable ? "disabled" : "header"}
      onChange={setActiveKey}
    >
      <AntCollapse.Panel
        key={"1"}
        children={children}
        header={
          <Text
            weight="600"
            children={title}
            opacity={disable ? 0.5 : 1}
            type={done ? "success" : "primary"}
          />
        }
      />
    </StyledCollapse>
  );
};

export default Collapse;
