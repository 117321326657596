import React, { useImperativeHandle, useRef } from "react";
import ForgotPassword from "./forgotPassword";
import Localization from "../../localization";
import { Modal } from "../../components";
import Register from "./register";
import Login from "./login";

const AuthLayout = (
  { onAuthComplete = async () => {}, logoutOnError },
  ref
) => {
  const forgotPassModalRef = useRef({});
  const registerModalRef = useRef({});
  const loginModalRef = useRef({});

  useImperativeHandle(
    ref,
    () => ({
      login: loginModalRef.current,
      forgot: forgotPassModalRef.current,
      register: registerModalRef.current,
    }),
    []
  );

  return (
    <React.Fragment>
      <Modal
        ref={loginModalRef}
        title={Localization.dashboard.login_modal_title}
        child={(data) => (
          <Login
            logoutOnError={logoutOnError}
            onRegisterClick={() => {
              loginModalRef.current.close();
              registerModalRef.current.open(data);
            }}
            onForgetPassClick={() => {
              loginModalRef.current.close();
              forgotPassModalRef.current.open(data);
            }}
            onLogin={async () => {
              await onAuthComplete(data);
              loginModalRef.current.close();
            }}
          />
        )}
      />
      <Modal
        ref={registerModalRef}
        title={Localization.dashboard.register_modal_title}
        child={(data) => (
          <Register
            logoutOnError={logoutOnError}
            onRegister={async () => {
              await onAuthComplete(data);
              registerModalRef.current.close();
            }}
            onLoginClick={() => {
              registerModalRef.current.close();
              loginModalRef.current.open(data);
            }}
          />
        )}
      />
      <Modal
        ref={forgotPassModalRef}
        title={Localization.dashboard.forgot_password_modal_title}
        child={(data) => (
          <ForgotPassword
            onFinish={() => {
              forgotPassModalRef.current.close();
            }}
            onLoginClick={() => {
              forgotPassModalRef.current.close();
              loginModalRef.current.open(data);
            }}
          />
        )}
      />
    </React.Fragment>
  );
};

export default React.forwardRef(AuthLayout);
