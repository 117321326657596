import React, { useCallback, useEffect, useRef, useState } from "react";
import UnavailableAlarm from "./unavailableAlarm";
import { Modal, Text } from "../../components";
import { ServicesApi } from "../../apis";
import styled from "styled-components";
import AlarmTerms from "./alarmTerms";
import { Flex, List } from "antd";

const AlarmListLayout = ({ onSelectItem = () => {} }) => {
  const [data, setData] = useState([]);
  const unavailableAlarmModalRef = useRef();
  const termsModalRef = useRef();

  useEffect(() => {
    ServicesApi.devices().then(setData);
  }, []);

  const renderListItem = useCallback((item) => {
    const { image, desc, name, terms, color, uuid } = item;
    return (
      <StyledListItem
        className="cursor-pointer"
        extra={!!image && <img className="image" src={image} />}
        onClick={() => {
          if (!uuid) unavailableAlarmModalRef.current.open({}, { title: name });
          else if (!!terms) termsModalRef.current.open(item, { title: name });
          else onSelectItem(item);
        }}
      >
        <List.Item.Meta
          description={desc}
          title={<Text type={color} children={name} />}
        />
      </StyledListItem>
    );
  }, []);

  return (
    <React.Fragment>
      <Flex
        gap="2em"
        vertical
        children={React.Children.toArray(
          data.map((dataSource) => (
            <List
              bordered
              itemLayout="horizontal"
              dataSource={dataSource}
              renderItem={renderListItem}
            />
          ))
        )}
      />

      <Modal
        ref={termsModalRef}
        child={(data) => (
          <AlarmTerms
            terms={data.terms}
            onCancel={() => termsModalRef.current.close()}
            onContinue={() => {
              termsModalRef.current.close();
              onSelectItem(data);
            }}
          />
        )}
      />
      <Modal
        ref={unavailableAlarmModalRef}
        child={() => (
          <UnavailableAlarm
            onSend={() => unavailableAlarmModalRef.current.close()}
          />
        )}
      />
    </React.Fragment>
  );
};

const StyledListItem = styled(List.Item)`
  &:hover {
    background: rgba(0, 0, 0, 0.05);
    transition: 0.5s ease;
  }
  > .image {
    width: 3em;
  }
  > .icon {
    font-size: 3em;
    color: ${(_) => _.theme.token.colorPrimary};
  }
`;

export default AlarmListLayout;
