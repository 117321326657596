import { Enums, Tools } from "../utils";
import api from "./api";

const createProperty = (
  connectId,
  { latitude, longitude, title, address, message }
) =>
  new Promise(async (resolve, reject) => {
    const userId = Tools.storage.getItem(Enums.storageKeys.userUuid);
    try {
      const data = await api.post(`/rest/users/${userId}/property`, {
        data: {
          title,
          address,
          latitude,
          connectId,
          longitude,
          radius: 2500,
          type: "primary",
          notifications: true,
        },
      });
      const splittedLoc = data.location.split("/");
      resolve({ ...data, id: splittedLoc[splittedLoc.length - 1] });
    } catch (error) {
      reject({ error });
    }
  });

const addPropertyToConnect = (connectId, uuid) =>
  new Promise(async (resolve, reject) => {
    const userId = Tools.storage.getItem(Enums.storageKeys.userUuid);

    try {
      const data = await api.put(
        `/rest/users/${userId}/subscriptions/${connectId}/property/${uuid}`
      );
      resolve(data);
    } catch (error) {
      reject({ error });
    }
  });

const updateEvent = (
  uuid,
  { groups = [], message = "", signals = [], delay = 0, webhooks = [] } = {}
) =>
  new Promise(async (resolve, reject) => {
    try {
      const data = await api.put(`rest/devices/${uuid}/configurations/events`, {
        data: [{ categoryId: 1, groups, message, signals, delay, webhooks }],
      });
      resolve(data);
    } catch (error) {
      reject({ error });
    }
  });

export default {
  updateEvent,
  createProperty,
  addPropertyToConnect,
};
