import { Button, Form, Text } from "../../../components";
import React, { useRef, useContext } from "react";
import Localization from "../../../localization";
import { Tools } from "../../../utils";
import { ServiceContext } from "..";

const Webhook = ({ edit = false, onConfirm = async () => {} }) => {
  const { device: { distribution: { webhook } = {} } = {} } =
    useContext(ServiceContext);
  const formRef = useRef();

  return (
    <div>
      <Text
        children={Localization.service_edit_collaborators.webhook_modal_desc}
      />
      <Form
        ref={formRef}
        className="m-10-t"
        fields={[
          {
            value: webhook?.url,
            name: ["data", "url"],
            placeholder: "https.mysite.com",
            label: Localization.general.webhook_url,
            rules: [
              {
                required: true,
                message: Localization.feedback.enter_valid_url,
                pattern: new RegExp(
                  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi
                ),
              },
            ],
          },
          {
            face: "select",
            name: ["data", "method"],
            value: webhook?.method ?? "GET",
            label: Localization.general.method,
            options: ["GET", "POST"].map((value) => ({ value, label: value })),
          },
          {
            type: "textarea",
            name: ["data", "body"],
            value: webhook?.body,
            autoSize: { minRows: 4, maxRows: 6 },
            label: Localization.general.request_body,
            placeholder: `{ "message": "Call webhook" }`,
            rules: [
              {
                validator: (_, value) =>
                  !value || Tools.isJSON(value)
                    ? Promise.resolve()
                    : Promise.reject(Localization.feedback.enter_valid_JSON),
              },
            ],
          },
        ]}
      />
      <Button
        type="primary"
        className="w-100 m-30-t"
        title={edit ? Localization.general.save : Localization.general.confirm}
        onClick={async () => {
          try {
            await formRef.current.validateFields();
            await onConfirm(formRef.current.getFieldValue("data"));
          } catch (error) {}
        }}
      />
    </div>
  );
};

export default Webhook;
