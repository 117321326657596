const storageKeys = {
  token: "token",
  userUuid: "userUuid",
  isSafelandMobileApp: "isSafelandMobileApp",
};

const paymentType = {
  card: 0,
  invoice: 1,
  activation: 2,
};

const Enums = { storageKeys, paymentType };

export default Enums;
