import Localization from "../../../localization";
import { AjaxApi } from "../../../apis";
import { useApi } from "../../../hooks";
import { ServiceContext } from "..";
import { List } from "antd";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  ContentLoader,
  ListItem,
  Button,
  Modal,
  Form,
  Text,
} from "../../../components";

const Ajax = ({ onDone = async () => {} }) => {
  const { device } = useContext(ServiceContext);
  const [loading, setLoading] = useState(false);
  const [ajaxUser, setAjaxUser] = useState({});
  const [hubs, setHubs] = useState([]);
  const { execute } = useApi();
  const ajaxLoginModal = useRef();
  const ajaxLoginForm = useRef();
  const connectedHubId = hubs.find(
    (_) => _.hubId === device?.identifier
  )?.hubId;

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    try {
      const user = await AjaxApi.user();
      const hubs = await AjaxApi.hubs();
      setHubs(hubs);
      setAjaxUser(user);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const renderAjaxModal = useCallback(() => {
    return (
      <div>
        <Form
          ref={ajaxLoginForm}
          fields={[
            {
              type: "email",
              name: ["data", "email"],
              placeholder: "name@example.com",
              label: Localization.general.email_address,
              rules: [{ type: "email", required: true }],
            },
            {
              type: "password",
              name: ["data", "password"],
              label: Localization.general.password,
              placeholder: Localization.general.type_password,
              rules: [{ min: 4, required: true }],
            },
          ]}
        />
        <Button
          type="primary"
          className="w-100 m-30-t"
          title={Localization.general.login}
          onClick={async () => {
            try {
              await ajaxLoginForm.current.validateFields();
              await execute(async () =>
                AjaxApi.login(ajaxLoginForm.current.getFieldValue("data"))
              );
              ajaxLoginModal.current.close();
              await getData();
            } catch (error) {}
          }}
        />
      </div>
    );
  }, [ajaxLoginForm]);

  if (loading)
    return (
      <ContentLoader
        skeletons={[
          { size: "large", height: 70 },
          { size: "small" },
          { size: "large", height: 70 },
          { size: "large", height: 70 * 3 },
        ]}
      />
    );
  return (
    <div>
      <ListItem
        bordered
        title={Localization.general.ajax_hubId}
        avatarImg={"https://testapp.safe.land/img/connect/ajax-hub.png"}
        description={connectedHubId || Localization.general.not_connected}
      />
      {!!connectedHubId && (
        <Text
          className="m-10-t"
          children={Localization.service.edit_alarm_connection_ajax_desc_1}
        />
      )}

      {!ajaxUser?.login ? (
        <React.Fragment>
          <Text
            className="m-8-v"
            children={
              Localization.service.edit_alarm_connection_ajax_login_title
            }
          />
          <Button
            type="primary"
            shape="default"
            className="w-100"
            title={Localization.general.login}
            onClick={() => ajaxLoginModal.current.open()}
          />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Text
            className="m-10-v"
            children={Localization.service.edit_alarm_connection_ajax_desc}
          />
          <ListItem
            bordered
            hoverable
            className="m-10-b"
            title={Localization.general.change_account}
            onClick={() => ajaxLoginModal.current.open()}
            description={Localization.formatString(
              Localization.service.edit_alarm_connection_ajax_login_desc,
              ajaxUser?.login ?? ""
            )}
          />
        </React.Fragment>
      )}
      {!!ajaxUser?.login && (
        <List
          bordered
          dataSource={hubs}
          itemLayout="horizontal"
          renderItem={({ name, hubId, image }) => {
            const isConnected = hubId === connectedHubId;
            return (
              <ListItem
                hoverable
                title={name}
                extraImg={image}
                description={hubId}
                disabled={isConnected}
                onClick={() => onDone(hubId)}
                tags={[
                  { children: Localization.general.connected.toUpperCase() },
                ].slice(0, isConnected ? 1 : 0)}
              />
            );
          }}
        />
      )}
      <Modal
        ref={ajaxLoginModal}
        child={renderAjaxModal}
        title={Localization.service.edit_alarm_connection_ajax_login_title}
      />
    </div>
  );
};

export default Ajax;
