import { useContext } from "react";
import { ContextType, GeneralContext } from "../context";

const useGeneral = () => {
  const { isSafelandMobileApp, dispatch } = useContext(GeneralContext);

  const setIsSafelandMobileApp = (payload) => {
    dispatch(ContextType.general.SET_IS_GENERAL_MOBILE_APP, payload);
  };

  return { isSafelandMobileApp, setIsSafelandMobileApp };
};

export default useGeneral;
