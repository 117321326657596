import React, { useCallback, useEffect, useImperativeHandle } from "react";
import { Form as AntForm, Checkbox, Col, Input, Row, Select } from "antd";
import AutoComplete from "../autoComplete";

const Item = ({ face, ...props }) => {
  if (face === "select") return <Select {...props} />;
  else if (face === "checkbox") return <Checkbox {...props} />;
  else if (face === "autoComplete") return <AutoComplete {...props} />;
  else if (props.type === "password") return <Input.Password {...props} />;
  else if (props.type === "textarea") return <Input.TextArea {...props} />;
  return <Input {...props} />;
};

const Form = (
  { fields = [], children, renderFormItem = () => null, ...props },
  ref
) => {
  const [form] = AntForm.useForm();

  useEffect(() => {
    const initialValues = fields.flat().filter((_) => _.value !== undefined);
    if (!!initialValues.length)
      form.setFields(
        initialValues.map((_) => ({ name: _.name, value: _.value }))
      );
  }, []);

  useImperativeHandle(ref, () => form, []);

  const renderItem = useCallback((item) => {
    const { label, name, rules, valuePropName, face = "", ...props } = item;
    return (
      renderFormItem(item) ?? (
        <AntForm.Item
          label={label}
          rules={rules}
          name={name}
          className={`w-100 m-0`}
          valuePropName={valuePropName}
          children={<Item face={face} {...props} />}
        />
      )
    );
  }, []);

  return (
    <AntForm
      form={form}
      layout="vertical"
      autoComplete="on"
      style={{ gap: ".5em", display: "flex", flexDirection: "column" }}
      {...props}
    >
      {React.Children.toArray(
        fields.map((item) =>
          Array.isArray(item) ? (
            <Row
              gutter={[12, 12]}
              children={item.map((data) => (
                <Col
                  sm={24}
                  xs={24}
                  md={24 / item.length}
                  children={renderItem(data)}
                />
              ))}
            />
          ) : (
            renderItem(item)
          )
        )
      )}
      {children}
    </AntForm>
  );
};

export default React.forwardRef(Form);
