import { Flex, Skeleton } from "antd";
import React from "react";

const ContentLoader = ({ skeletons = [], gap = "0.5em", ...props }) => {
  return (
    <Flex vertical gap={gap} {...props}>
      {React.Children.toArray(
        skeletons.map(
          ({ height, width = "100%", top = 0, size, ...skeleton }) => (
            <Flex style={{ width, marginTop: top }} {...skeleton}>
              <Skeleton.Button
                active
                className="w-100"
                style={{ height }}
                size={size}
              />
            </Flex>
          )
        )
      )}
    </Flex>
  );
};

export default ContentLoader;
