import { Text } from "../../components";
import { Flex } from "antd";
import React from "react";
import styled from "styled-components";

const Styled = styled(Flex)`
  .text {
    padding: 1em;
    border: 1px solid ${(_) => _.theme.token.colorPrimary};
  }
`;

const Copyable = ({ text, ...props }) => (
  <Styled align="center" justify="center" {...props}>
    <Text copyable className="text" children={text} />
  </Styled>
);

export default Copyable;
