import fullOrangeLogo from "./full-orange-logo.png";
import AjaxAlarmSystem from "./ajax-systems.jpg";
import Arlo from "./arlo.png";
import NestCam from "./nest-cam.png";
import NestProtect from "./nest-protect.png";
import Netatmo from "./netatmo.jpg";
import Point from "./point.png";
import Sikkerthjem from "./sikkerthjem.jpg";
import VisonicPowermaster from "./visonic-powermaster.png";
import YoyoCam from "./yoyocam.jpg";
import Dashboard1 from "./dashboard-1.png";
import Dashboard2 from "./dashboard-2.png";
import ConnectHttp from "./connect-http.png";
import MapMarker from "./map-marker.png";
import LicenseActivation from "./license-activation.png";

const Images = {
  fullOrangeLogo,
  AjaxAlarmSystem,
  Arlo,
  NestCam,
  NestProtect,
  Netatmo,
  Point,
  Sikkerthjem,
  VisonicPowermaster,
  YoyoCam,
  Dashboard1,
  Dashboard2,
  ConnectHttp,
  MapMarker,
  LicenseActivation,
};

export default Images;
