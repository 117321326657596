const LightTheme = {
  token: {
    fontFamily: `"Montserrat", sans-serif`,
    colorPrimary: "rgba(3, 75, 95 , 1)",
    divider: "rgba(0, 0, 0, 0.2)",
    success: "rgba(97,184,146,1)",
    fontColors: {
      primary: "rgba(3, 75, 95 , 1)",
      secondary: "rgba(255, 255 , 255, 1)",
      tertiary: "rgba(0, 0 , 0, 1)",
      destructive: "rgba(210, 43, 43, 1)",
      base: "rgba(255, 137, 86, 1)",
      success: "rgba(97,184,146,1)",
      gray: "rgba(0, 0, 0, 0.6)",
    },
  },
  colors: {
    primary: "#orange",
    background: "rgba(243, 243, 243, 1)",
    surface: {
      primary: "rgba(255, 255 , 255, 1)",
    },
    font: {
      primary: "#174B5F",
      secondary: "rgba(255, 255 , 255, 1)",
      tertiary: "rgba(0, 0 , 0, 1)",
    },
  },
  shadow: {
    box19: `box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;`,
    box33: `box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;`,
    box29: `box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;`,
  },
};

export { LightTheme };
