import { Button as AntButton } from "antd";
import React, { useState } from "react";
import styled from "styled-components";

const Styled = styled(AntButton)`
  height: 2.8em;
  min-width: 2.8em;
  ${(_) =>
    _.success &&
    `background: ${_.theme.token.success};
    &:hover {
      background: ${_.theme.token.success} !important;
      opacity: .9;
    }`}
`;

const Button = ({
  title = "",
  outlined = false,
  success = false,
  shape = "round",
  onClick = async () => {},
  ...props
}) => {
  const [loading, setLoading] = useState(false);

  const onClickButton = async () => {
    setLoading(true);
    try {
      await onClick();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Styled
      shape={shape}
      children={title}
      success={success}
      loading={loading}
      onClick={onClickButton}
      {...props}
    />
  );
};

export default Button;
