import { Button, Form, Modal, Text } from "../../../components";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import Localization from "../../../localization";
import React, { useRef, useState } from "react";
import { Col, Flex, Row, Table } from "antd";
import styled from "styled-components";

const fields = (values = {}) => [
  {
    value: values.name,
    name: ["data", "name"],
    rules: [{ required: true }],
    title: Localization.general.name,
    label: Localization.general.your_full_name,
    placeholder: Localization.general.firstLastName,
  },
  [
    {
      value: values.phone,
      name: ["data", "phone"],
      rules: [{ required: true }],
      placeholder: "0123 456 78 90",
      title: Localization.general.phone,
      label: Localization.general.phone_number,
    },
    {
      value: values.email,
      name: ["data", "email"],
      title: Localization.general.email,
      rules: [{ required: true, type: "email" }],
      label: Localization.general.email_address,
      placeholder: "name@example.com",
    },
  ],
  {
    value: values.password,
    name: ["data", "password"],
    rules: [{ required: true }],
    label: Localization.general.code_word,
    placeholder: Localization.general.voluntarily,
  },
  {
    title: Localization.general.admin,
    face: "checkbox",
    value: values.authority === 5,
    name: ["data", "authority"],
    valuePropName: "checked",
    children: (
      <div>
        <Text weight={"600"} children={Localization.general.admin} />
        <Text
          children={
            Localization.service_edit_alarm_operator.contacts_admin_desc
          }
        />
      </div>
    ),
  },
];

export const NominatedContacts = ({
  contacts = [],
  onEdit = undefined,
  ...props
}) => {
  return (
    <StyledTable
      {...props}
      pagination={false}
      scroll={{ y: 270 }}
      dataSource={contacts.map((_, key) => ({ ..._, key, operation: key }))}
      columns={[
        ...fields()
          .flat()
          .map((_) => ({
            dataIndex: _.name[1],
            title: _.title || _.label,
            width: 150,
            render: (text) =>
              _.name[1] === "authority"
                ? text == 5
                  ? Localization.general.yes
                  : Localization.general.no
                : text,
          })),
        {
          width: 120,
          dataIndex: "operation",
          title: "",
          render: (index) =>
            !index ? null : (
              <Button
                success
                type="primary"
                onClick={() => onEdit(index)}
                title={Localization.general.edit}
              />
            ),
        },
      ].slice(0, !!onEdit && contacts.length > 1 ? undefined : -1)}
    />
  );
};

const Contacts = ({
  contacts = [],
  onNext = () => {},
  onBack = () => {},
  onAdd = () => {},
  onEdit = () => {},
  onRemove = () => {},
}) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const modalRef = useRef();
  const formRef = useRef();

  const updateContact = async (index) => {
    try {
      await formRef.current.validateFields();
      const data = formRef.current.getFieldValue("data");
      data.authority = data.authority ? 5 : 1;
      !!index ? onEdit(index, data) : onAdd(data);
      formRef.current.resetFields();
      modalRef.current.close();
    } catch (error) {}
  };

  return (
    <div>
      <Text
        weight="600"
        children={`${Localization.general.step} 2: ${Localization.service_edit_alarm_operator.contacts_title}`}
      />
      <Text children={Localization.service_edit_alarm_operator.contacts_desc} />
      <Row
        gutter={[12, 12]}
        className="m-20-t"
        align={"middle"}
        justify={"space-between"}
      >
        <Col xm={24} sm={24} md={12}>
          <Text
            level={3}
            children={
              Localization.service_edit_alarm_operator.contacts_table_title
            }
          />
        </Col>
        <Col className="p-0 w-100" xm={24} sm={24} md={12}>
          <Flex gap={".5em"}>
            {!!selectedRows.length && (
              <Button
                danger
                type="primary"
                shape="default"
                icon={<DeleteOutlined />}
                onClick={() => {
                  setSelectedRows([]);
                  onRemove(selectedRows);
                }}
              />
            )}
            <Button
              type="primary"
              shape="default"
              className="w-100"
              icon={<PlusOutlined />}
              onClick={() => modalRef.current.open({})}
              title={
                Localization.service_edit_alarm_operator.contacts_form_title
              }
            />
          </Flex>
        </Col>
      </Row>
      <NominatedContacts
        contacts={contacts}
        className="m-10-t"
        onEdit={(index) => modalRef.current.open({ index })}
        rowSelection={{
          type: "checkbox",
          getCheckboxProps: (record) => ({ disabled: !record.key }),
          onChange: (indexes) => {
            setSelectedRows(indexes);
          },
        }}
      />
      <Flex gap={"1em"} className="m-40-t">
        <Button
          className="w-100"
          onClick={() => onBack()}
          title={Localization.general.back}
        />
        <Button
          title={Localization.general.save_and_cont}
          onClick={() => onNext({})}
          className="w-100"
          type="primary"
        />
      </Flex>
      <Modal
        ref={modalRef}
        title={"Add nominee to call list"}
        child={({ index } = {}) => (
          <div>
            <Form ref={formRef} fields={fields(contacts[index] ?? {})} />
            <Button
              type="primary"
              className="w-100 m-20-t"
              onClick={() => updateContact(index)}
              title={
                !!index ? Localization.general.save : Localization.general.add
              }
            />
          </div>
        )}
      />
    </div>
  );
};

const StyledTable = styled(Table)`
  .ant-table-tbody .ant-table-row.ant-table-row-selected > .ant-table-cell {
    background: none;
  }
`;

export default Contacts;
