import { Button, Text } from "../../components";
import Localization from "../../localization";
import { useParams } from "react-router-dom";
import { Card, Col, Input, Row } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import { Tools } from "../../utils";

const Styled = styled(Card)`
  .ant-input {
    font-size: 1.6em;
    padding: 0 10px;
    font-weight: 600;
    text-align: center;
  }
`;

const InvalidLicense = ({ onDone }) => {
  const { uuid = "" } = useParams();
  const [value, setValue] = useState(uuid);

  return (
    <Styled title={Localization.activate.header_title}>
      <Row justify="center">
        <Col xs={24} sm={24} md={14} lg={14}>
          <Text
            level={4}
            align={"center"}
            children={Localization.activate.invalid_title}
          />
          <Text
            className="m-5-t"
            align={"center"}
            children={Localization.activate.invalid_desc}
          />
        </Col>
      </Row>
      <Row justify="center">
        <Col xs={24} sm={24} md={20} lg={16}>
          <Input
            maxLength="24"
            className="m-20-v"
            value={Tools.maskLicenseKey(value)}
            placeholder="xxxx xxxx xxxx xxxx xxxx"
            onChange={({ target }) => {
              setValue(target.value.replace(/ /g, ""));
            }}
          />
        </Col>
      </Row>
      <Button
        type="primary"
        className="w-100"
        disabled={value.length !== 20}
        title={Localization.general.confirm}
        onClick={() => onDone(value.replace(/ /g, ""))}
      />
    </Styled>
  );
};

export default InvalidLicense;
