import { Button, Form, Text } from "../../components";
import React, { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Localization from "../../localization";
import { Constants } from "../../utils";
import { AuthApi } from "../../apis";
import { useApi } from "../../hooks";
import { Divider, Flex } from "antd";

const fields = [
  {
    type: "email",
    name: ["data", "email"],
    placeholder: "name@example.com",
    label: Localization.general.email_address,
    rules: [{ type: "email", required: true }],
  },
];

const ForgotPassword = ({ onLoginClick = () => {}, onFinish = () => {} }) => {
  const [captcha, setCaptcha] = useState("");
  const { execute } = useApi();
  const formRef = useRef();

  const forgotPassword = async () => {
    try {
      await formRef.current.validateFields();
      const { email } = formRef.current.getFieldValue("data");
      await execute(async () => AuthApi.forgotPassword({ email, captcha }), {
        success: Localization.formatString(
          Localization.feedback.forgot_password_success,
          email
        ),
      });
      onFinish();
    } catch (error) {}
  };

  return (
    <div>
      <Flex vertical align="center" gap={"1em"}>
        <Form className="w-100" fields={fields} ref={formRef} />
        <ReCAPTCHA
          onChange={setCaptcha}
          sitekey={Constants.Project.reCAPTCHASitekey}
        />
      </Flex>
      <Button
        type="primary"
        disabled={!captcha}
        onClick={forgotPassword}
        className="w-100 m-25-t"
        title={Localization.forgot_password.btn}
      />
      <Divider className="m-12-v" />
      <Text className="text-align-center">
        {Localization.forgot_password.footer_1 + " "}
        <Text
          underline
          level="link"
          onClick={onLoginClick}
          children={Localization.forgot_password.footer_2}
        />
      </Text>
    </div>
  );
};

export default ForgotPassword;
