import Localization from "../../../localization";
import { Text } from "../../../components";
import React, { useContext } from "react";
import { Flex, Tag, theme } from "antd";
import { ServiceContext } from "..";

const Ajax = () => {
  const { device } = useContext(ServiceContext);
  const { token } = theme.useToken();

  return (
    <Flex justify="space-between" align="flex-start">
      <Flex gap={"1em"} align="center">
        {!!device?.alarm?.image && (
          <img src={device?.alarm.image} style={{ width: "7em" }} />
        )}
        <div>
          <Text level={4} children={device?.alarm?.name} />
          <Text
            children={`Hub ID: ${
              device?.identifier ?? Localization.general.not_connected
            }`}
          />
        </div>
      </Flex>
      <Tag
        color={token.fontColors.success}
        children={Localization.general.connected.toUpperCase()}
      />
    </Flex>
  );
};

export default Ajax;
