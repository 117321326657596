import { MapView, Text } from "../../components";
import Localization from "../../localization";
import { Card } from "antd";
import React from "react";

const DeviceCard = ({
  center,
  name = "",
  active = false,
  className = "",
  onClick = () => {},
}) => {
  return (
    <Card
      hoverable
      onClick={onClick}
      classNames={{ body: "p-0" }}
      className={`${className} overflow-hidden`}
    >
      <MapView
        zoom={18}
        center={center}
        style={{ height: "200px" }}
        markers={[{ position: center }]}
        options={{
          disableDefaultUI: true,
          disableDoubleClickZoom: true,
          scaleControl: false,
          gestureHandling: "none",
          scrollwheel: false,
        }}
      />
      <div className="p-20">
        {active && <Text children={Localization.general.connect} />}
        <Text
          level={4}
          children={active ? name : Localization.general.connect}
        />
        {!active && (
          <Text children={Localization.dashboard.device_notconfig_desc} />
        )}
      </div>
    </Card>
  );
};

export default DeviceCard;
