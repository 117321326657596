import { Layout } from "antd";
import styled from "styled-components";

const Styled = styled(Layout.Header)`
  ${(_) => `
  top: 0;
  padding: 0;
  z-index: 1;
  display: flex;
  position: sticky;
  align-items: center;
  justify-content: space-between;
  background-color: ${_.theme.colors.surface.primary};
  ${_.theme.shadow.box19}
  .logo { 
    height: 2.5rem;
    cursor: pointer;
   }
`}
`;

export default Styled;
