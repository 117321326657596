import React, { useRef, useState } from "react";
import { Button, Modal, Text } from "../../../components";
import Localization from "../../../localization";
import Copyable from "../copyable";
import { Constants } from "../../../utils";
import { Checkbox, Input } from "antd";

const Phone = ({ onDone = async () => {} }) => {
  const [accepted, setAccepted] = useState(false);
  const [number, setNumber] = useState("");
  const numberModalRef = useRef();

  const onConfirm = async () => {
    await onDone(number);
    numberModalRef.current.close();
  };

  return (
    <div>
      <Text weight={"600"} children={Localization.service_edit_phone.title} />
      <Text children={Localization.service_edit_phone.desc_1} />
      <Text children={Localization.service_edit_phone.desc_2} />
      <Copyable
        className="m-10-v"
        text={Constants.Project.safelandPhoneNumber}
      />
      <Checkbox
        value={accepted}
        className="m-20-b color-inherit"
        children={Localization.service_edit_phone.condition}
        onChange={(_) => setAccepted(_.target.checked)}
      />
      <Button
        type="primary"
        className="w-100"
        disabled={!accepted}
        title={Localization.general.continue}
        onClick={() => numberModalRef.current.open()}
      />
      <Modal
        ref={numberModalRef}
        title={Localization.general.connect_alarm}
        child={() => (
          <div>
            <Text
              weight={"600"}
              children={Localization.service_edit_phone.number_modal_title}
            />
            <Text
              children={Localization.service_edit_phone.number_modal_desc}
            />
            <Input
              value={number}
              className=" m-15-t m-25-b"
              placeholder="+46 000 000 00 00"
              onChange={(_) => setNumber(_.target.value)}
            />
            <Button
              type="primary"
              className="w-100"
              onClick={() => onConfirm()}
              disabled={number.length < 12}
              title={Localization.general.confirm}
            />
          </div>
        )}
      />
    </div>
  );
};

export default Phone;
