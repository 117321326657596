import { Enums, Tools } from "../utils";
import api from "./api";

const update = (
  uuid,
  { groups = [], message = "", signals = [], delay = 0, webhooks = [] } = {}
) =>
  new Promise(async (resolve, reject) => {
    try {
      const data = await api.put(`rest/devices/${uuid}/configurations/events`, {
        data: [{ categoryId: 1, groups, message, signals, delay, webhooks }],
      });
      resolve(data);
    } catch (error) {
      reject({ error });
    }
  });

const testWebhook = (uuid) =>
  new Promise(async (resolve, reject) => {
    const userId = Tools.storage.getItem(Enums.storageKeys.userUuid);
    try {
      await api.post(
        `rest/users/${userId}/services/${uuid}/recipients/webhooks/test`
      );
      resolve();
    } catch (error) {
      reject({ error });
    }
  });

export default {
  update,
  testWebhook,
};
