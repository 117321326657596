import { Button, Text } from "../../../components";
import Localization from "../../../localization";
import React, { useCallback } from "react";
import { Flex, Radio } from "antd";

const types = {
  burglary: { title: Localization.general.burglary },
  fire: { title: Localization.general.fire },
  fight: { title: Localization.general.fight },
  emergency: { title: Localization.general.emergency },
  sabotage: { title: Localization.general.sabotage },
  tech: { title: Localization.general.tech },
};

const options = [
  Localization.service_edit_alarm_operator.actions_option_1,
  Localization.service_edit_alarm_operator.actions_option_2,
  Localization.service_edit_alarm_operator.actions_option_3,
  Localization.service_edit_alarm_operator.actions_option_4,
  Localization.service_edit_alarm_operator.actions_option_5,
];

export const ActionTypes = ({
  actions = [],
  onSelect = () => {},
  renderActionType = () => null,
  ...props
}) => {
  const renderType = useCallback(({ type, action }) => {
    const { title } = types[type];
    return (
      renderActionType({
        title,
        value: options[action],
      }) ?? (
        <div {...props}>
          <Text level={5} children={title} />
          <Flex
            vertical
            gap={".5em"}
            className="m-5-t"
            children={React.Children.toArray(
              options.map((option, index) => (
                <Radio
                  checked={index == action}
                  children={<Text children={option} />}
                  onClick={() => onSelect(type, index)}
                />
              ))
            )}
          />
        </div>
      )
    );
  }, []);

  return (
    <Flex
      vertical
      gap={"1em"}
      className="m-10-t"
      children={React.Children.toArray(actions.map(renderType))}
    />
  );
};
const Actions = ({
  actions = [],
  onNext = () => {},
  onBack = () => {},
  onSelect = () => {},
}) => {
  return (
    <div>
      <Text
        weight="600"
        children={`${Localization.general.step} 3: ${Localization.service_edit_alarm_operator.actions_title}`}
      />
      <Text
        children={Localization.service_edit_alarm_operator.actions_desc_1}
      />
      <Text
        children={Localization.service_edit_alarm_operator.actions_desc_2}
      />
      <ActionTypes onSelect={onSelect} actions={actions} className="m-10-t" />
      <Flex gap={"1em"} className="m-30-t">
        <Button
          onClick={() => onBack()}
          className="w-100"
          title={Localization.general.back}
        />
        <Button
          onClick={() => onNext({})}
          className="w-100"
          type="primary"
          title={Localization.general.save_and_cont}
        />
      </Flex>
    </div>
  );
};

export default Actions;
