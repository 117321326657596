import Localization from "../localization";
import { Enums, Tools } from "../utils";
import { Images } from "../assets";
import api from "./api";

const devices = () =>
  new Promise(async (resolve) => {
    try {
      const data = [
        [
          {
            uuid: 101,
            name: "Verisure",
            vendor: "Verisure",
            desc: "Verisure",
            color: "destructive",
            integration: "verisure",
            terms: Localization.api.services.devices.terms,
          },
          {
            name: "Sector",
            vendor: "Sector",
            desc: "Sector",
            color: "destructive",
            integration: "sector",
          },
          {
            name: "Alert Alarm",
            vendor: "Alert Alarm",
            desc: "Alert Alarm",
            integration: "alert_alarm",
          },
        ],
        [
          {
            name: "Ajax",
            uuid: 120,
            integration: "ajax",
            vendor: "Ajax Systems",
            desc: "Ajax Systems",
            image: Images.AjaxAlarmSystem,
          },
          {
            uuid: 119,
            integration: "sms",
            image: Images.Sikkerthjem,
            vendor: "Sikkerthjem",
            desc: "Sikkerthjem",
            name: "SikkertHjem S6evo/S7evo",
          },
          {
            name: "Visonic PowerMaster 10",
            uuid: 100,
            integration: "sms",
            vendor: "Visonic",
            desc: "Visonic",
            image: Images.VisonicPowermaster,
          },
          {
            name: "YOYOCam 3G Indoor",
            uuid: 104,
            integration: "sms",
            vendor: "YOYO",
            desc: "YOYO",
            image: Images.YoyoCam,
          },
          // {
          //   name: "Arlo",
          //   uuid: 10001,
          //   integration: "http",
          //   vendor: "Arlo",
          //   desc: "Arlo",
          //   image: Images.Arlo,
          // },
          {
            name: "Nest Protect",
            uuid: 10002,
            integration: "http",
            vendor: "Nest",
            desc: "Nest",
            image: Images.NestProtect,
          },
          // {
          //   name: "Netatmo",
          //   uuid: 10003,
          //   integration: "http",
          //   vendor: "Netatmo",
          //   desc: "Netatmo",
          //   image: Images.Netatmo,
          // },
          // {
          //   name: "Nest Cam",
          //   uuid: 10006,
          //   integration: "http",
          //   vendor: "Nest",
          //   desc: "Nest",
          //   image: Images.NestCam,
          // },
          {
            name: "Point",
            uuid: 10008,
            integration: "http",
            vendor: "Minut",
            desc: "Minut",
            image: Images.Point,
          },
        ],
        [
          {
            uuid: 192020,
            vendor: "",
            integration: "http",
            image: Images.ConnectHttp,
            name: Localization.services.alarm_list_footer_title,
            desc: Localization.services.alarm_list_footer_subtitle,
          },
        ],
      ];
      resolve(data);
    } catch (error) {}
  });

const device = async (uuid) => {
  const data = await devices();
  return data.flat().find((_) => _.uuid === (uuid === 20002 ? 120 : uuid));
};

const devicePackages = () =>
  new Promise(async (resolve) => {
    try {
      const data = [
        // {
        //   title: "Folklarmet",
        //   subTitle: "SikkertHjem S6evo/S7evo",
        //   desc: Localization.api.services.devicePackages.folklarmet_desc,
        //   image: Images.Sikkerthjem,
        //   readMoreLink: "https://www.safe.land/butik/sikkerthjem/",
        //   orderLink:
        //     "https://www.safe.land/se/connect/sikkerthjem-och-safeland/",
        // },
        {
          title: "Safelands populära hemlarm",
          subTitle: "Ajax Systems",
          desc: Localization.api.services.devicePackages.premiumlarmet_desc,
          image: Images.AjaxAlarmSystem,
          readMoreLink: "https://www.safe.land/se/premiumlarm",
          orderLink: "https://www.safe.land/butik/ajax/",
        },
      ];
      resolve(data);
    } catch (error) {}
  });

const paymentPlans = () =>
  new Promise(async (resolve, reject) => {
    try {
      const plans = [
        {
          amount: 49,
          service: "connect-homes-se",
          annualAmount: 490,
          title: Localization.general.connect,
          infos: Localization.api.services.paymentPlans.basic_infos,
        },
        {
          amount: 149,
          service: "connect-plus-se",
          annualAmount: 1490,
          title: Localization.general.connect_plus,
          infos: Localization.api.services.paymentPlans.plus_infos,
        },
      ];
      resolve(plans);
    } catch (error) {}
  });

const services = () =>
  new Promise(async (resolve, reject) => {
    const userId = Tools.storage.getItem(Enums.storageKeys.userUuid);
    try {
      const data = await api.get(`/rest/users/${userId}/services`);
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });

const service = (uuid) =>
  new Promise(async (resolve, reject) => {
    const userId = Tools.storage.getItem(Enums.storageKeys.userUuid);
    try {
      const data = await api.get(`/rest/users/${userId}/services/${uuid}`);
      resolve(data);
    } catch (_) {
      var error = {
        status: 500,
        message: Localization.feedback.something_wrong,
      };
      if (_.response.status === 404)
        error = {
          status: 404,
          message: Localization.feedback.ua_service,
        };
      reject(error);
    }
  });

const updateDevice = (uuid = "", options = {}) =>
  new Promise(async (resolve, reject) => {
    try {
      const data = await api.put(`/rest/devices/${uuid}`, { data: options });
      resolve(data);
    } catch (error) {
      if (error?.response?.status === 409)
        return reject({
          message:
            "THIS HUB ID IS ALREADY CONNECTED TO SAFELAND. CONTACT SAFELAND IF THIS PERSISTS.",
        });
      reject(error);
    }
  });

const arc = (uuid = "") =>
  new Promise(async (resolve, reject) => {
    const userId = Tools.storage.getItem(Enums.storageKeys.userUuid);
    try {
      const data = await api.get(
        `/rest/users/${userId}/services/${uuid}/arcinstructions`
      );
      resolve(data);
    } catch (error) {
      if (error?.response?.status === 404)
        return resolve({
          actions: [],
          contacts: [],
          customer: {},
        });
      reject(error);
    }
  });

const updateArc = (uuid = "", data = {}) =>
  new Promise(async (resolve, reject) => {
    const userId = Tools.storage.getItem(Enums.storageKeys.userUuid);
    try {
      await api.put(`/rest/users/${userId}/services/${uuid}/arcinstructions`, {
        data: {
          customer: data.customer,
          contacts: data.contacts,
          actions: data.actions,
        },
      });
      resolve();
    } catch (error) {
      reject(error);
    }
  });

const updateProgress = (uuid = "", data = {}) =>
  new Promise(async (resolve, reject) => {
    const userId = Tools.storage.getItem(Enums.storageKeys.userUuid);
    try {
      await api.put(`rest/users/${userId}/services/${uuid}/progress`, {
        data,
      });
      resolve();
    } catch (error) {
      reject(error);
    }
  });

const checkout = ({
  service = "",
  name = "",
  type = "",
  successUrl = "",
  cancelUrl = "",
} = {}) =>
  new Promise(async (resolve, reject) => {
    const userId = Tools.storage.getItem(Enums.storageKeys.userUuid);
    try {
      const data = await api.post(
        `rest/users/${userId}/subscriptions/checkout`,
        {
          data: {
            service, // "connect-plus-se" | "connect-homes-se",
            paymentPlan: { name }, // "yearly" | "monthly"
            device: { type },
            successUrl,
            cancelUrl,
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });

const subscription = (sessionId) =>
  new Promise(async (resolve, reject) => {
    const userId = Tools.storage.getItem(Enums.storageKeys.userUuid);
    try {
      const data = await api.get(
        `rest/users/${userId}/subscriptions/checkout/${sessionId}`
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });

const subscriptionIntent = (subscriptionId, { service, name } = {}) =>
  new Promise(async (resolve, reject) => {
    const userId = Tools.storage.getItem(Enums.storageKeys.userUuid);
    try {
      const data = await api.post(`rest/users/${userId}/subscriptions/intent`, {
        data: {
          subscriptionId,
          service,
          paymentPlan: { name },
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });

const delSubscription = (sessionId) =>
  new Promise(async (resolve, reject) => {
    const userId = Tools.storage.getItem(Enums.storageKeys.userUuid);
    try {
      await api.delete(`/rest/users/${userId}/subscriptions/${sessionId}`);
      resolve();
    } catch (error) {
      reject(error);
    }
  });

const license = (uuid) =>
  new Promise(async (resolve, reject) => {
    try {
      const data = await api.get(`rest/licenses/${uuid}`);
      resolve(data);
    } catch (error) {
      if (error?.response?.status === 404)
        error.message = Localization.feedback.invalid_license_key;
      reject(error);
    }
  });

const activateLicense = (licenseKey) =>
  new Promise(async (resolve, reject) => {
    const userId = Tools.storage.getItem(Enums.storageKeys.userUuid);

    try {
      const data = await api.post(`rest/users/${userId}/services`, {
        data: { licenseKey },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });

const configured = (uuid) =>
  new Promise(async (resolve, reject) => {
    const userId = Tools.storage.getItem(Enums.storageKeys.userUuid);

    try {
      const data = await api.post(
        `/rest/users/${userId}/services/${uuid}/configured`
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });

export default {
  arc,
  device,
  devices,
  license,
  service,
  checkout,
  services,
  updateArc,
  configured,
  subscription,
  updateDevice,
  paymentPlans,
  devicePackages,
  delSubscription,
  updateProgress,
  activateLicense,
  subscriptionIntent,
};
