import { Avatar, Col, Divider, Flex, Popover, Row } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth, useGeneral } from "../../hooks";
import { Button, Text } from "../../components";
import Localization from "../../localization";
import { Images } from "../../assets";
import { Tools } from "../../utils";
import Styled from "./styled";
import React from "react";

const Header = () => {
  const { user, isAuthenticated, logout } = useAuth();
  const { isSafelandMobileApp } = useGeneral();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  if (pathname.includes("resetpassword") || isSafelandMobileApp) return null;
  return (
    <Styled>
      <Row className="w-100">
        <Col span={1} />
        <Col span={22}>
          <Flex justify="space-between" align="center">
            <div />
            <img
              className="logo"
              src={Images.fullOrangeLogo}
              onClick={() => navigate("/")}
            />
            {!isAuthenticated ? (
              <div />
            ) : (
              <Popover
                arrow
                placement="bottomRight"
                children={
                  <Avatar
                    className="cursor-pointer"
                    children={Tools.initials(user.alias)}
                  />
                }
                content={
                  <div>
                    <Text level={5} children={user.alias} />
                    <Text children={user.email} />
                    <Divider className="m-10-v" />
                    <Button
                      danger
                      shape="default"
                      color="danger"
                      type="primary"
                      className="w-100"
                      onClick={logout}
                      title={Localization.general.logout}
                    />
                  </div>
                }
              />
            )}
          </Flex>
        </Col>
        <Col span={1} />
      </Row>
    </Styled>
  );
};

export default Header;
