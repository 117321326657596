import { Button, Form, Text } from "../../components";
import Localization from "../../localization";
import { GoogleApi } from "../../apis";
import React, { useRef } from "react";

const AddHome = ({ onSave = async () => {} }) => {
  const formRef = useRef();
  return (
    <div>
      <Form
        className="m-20-b"
        ref={formRef}
        fields={[
          {
            name: ["data", "title"],
            rules: [{ required: true }],
            label: Localization.general.my_home,
            value: Localization.general.my_home,
            placeholder: Localization.add_home.placeholder_1,
          },
          {
            face: "autoComplete",
            name: ["data", "address"],
            rules: [
              { required: true, message: Localization.add_home.error_msg_2 },
            ],
            label: Localization.general.address,
            placeholder: Localization.add_home.placeholder_2,
            onSelect: (_, { extra } = {}) =>
              formRef.current.setFieldValue(["data", "coords"], extra),
            onSearch: async (text) => {
              const { places } = await GoogleApi.placeTextSearch(text, {
                fields: ["shortFormattedAddress", "displayName", "location"],
              });
              return places.map((_) => {
                const value = `${_.displayName.text}, ${_.shortFormattedAddress}`;
                return { value, label: value, extra: _.location };
              });
            },
          },
          {
            type: "textarea",
            name: ["data", "note"],
            autoSize: { minRows: 4, maxRows: 6 },
            placeholder: Localization.add_home.placeholder_3,
            label: `${Localization.general.note} (${Localization.general.optional})`,
          },
        ]}
      />
      <Button
        type="primary"
        className="w-100"
        onClick={async () => {
          try {
            await formRef.current.validateFields();
            await onSave(formRef.current.getFieldValue("data"));
          } catch (error) {}
        }}
        title={Localization.general.save}
      />
    </div>
  );
};

export default AddHome;
