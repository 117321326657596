import { Button, Form, ListItem, Modal, Text } from "../../components";
import { HomeOutlined, PlusCircleOutlined } from "@ant-design/icons";
import React, { useCallback, useContext, useRef } from "react";
import { EventApi, PropertyApi } from "../../apis";
import { useApi, useAuth } from "../../hooks";
import Localization from "../../localization";
import { AddHomeLayout } from "../../layouts";
import { useParams } from "react-router";
import { ServiceContext } from ".";
import { List } from "antd";

const EditLocationDetails = ({ onUpdate = async () => {} }) => {
  const { user: { property = [] } = {}, refreshUser } = useAuth();
  const { device } = useContext(ServiceContext);
  const addInfoModalRef = useRef();
  const addInfoFormRef = useRef();
  const addHomeModalRef = useRef();
  const { uuid } = useParams();
  const { execute } = useApi();

  const createProperty = async ({ title, address, coords, note }) => {
    await execute(async () => {
      const { id } = await PropertyApi.createProperty(uuid, {
        title,
        address,
        latitude: coords.latitude,
        longitude: coords.longitude,
      });
      await PropertyApi.addPropertyToConnect(uuid, id);
      if (!!note)
        await EventApi.update(device.id, {
          ...device.distribution,
          message: note,
        });
      await refreshUser();
      await onUpdate();
      addHomeModalRef.current.close();
    });
  };

  const addProperty = async (data) => {
    await execute(async () => {
      const message = addInfoFormRef.current.getFieldValue("note");
      if (device?.distribution?.message !== message)
        await EventApi.update(device.id, {
          ...device.distribution,
          message,
        });
      await PropertyApi.addPropertyToConnect(uuid, data.id);
      await onUpdate();
      addInfoModalRef.current.close();
    });
  };

  const renderItem = useCallback((item) => {
    return (
      <ListItem
        hoverable
        title={item.title}
        onClick={() => addInfoModalRef.current.open(item)}
        description={item.address || Localization.general.na}
        tags={[
          !!item.connectId && {
            children: Localization.general.is_connected.toUpperCase(),
          },
        ]}
        itemMeta={{
          avatar: (
            <Text level={2} children={<HomeOutlined className="item-icon" />} />
          ),
        }}
      />
    );
  }, []);

  const renderAddInfoModal = useCallback(
    (data) => (
      <div>
        <Text
          align="center"
          className="m-30-b"
          children={Localization.add_home.placeholder_3}
        />
        <Form
          ref={addInfoFormRef}
          fields={[
            {
              type: "textarea",
              name: ["note"],
              value: device?.distribution?.message,
              autoSize: { minRows: 4, maxRows: 6 },
              placeholder: Localization.add_home.add_info_placeholder,
              label: `${Localization.general.additional_info} (${Localization.general.optional})`,
            },
          ]}
        />
        <Button
          type="primary"
          className="w-100 m-20-t"
          title={Localization.general.save}
          onClick={() => addProperty(data)}
        />
      </div>
    ),
    [uuid, device?.distribution?.message]
  );

  return (
    <div>
      <Text
        align="center"
        className="m-15-b"
        children={Localization.service.edit_loc_desc}
      />
      {!!property.length && (
        <List bordered dataSource={property} renderItem={renderItem} />
      )}
      <ListItem
        hoverable
        bordered
        className={`m-12-t`}
        onClick={() => addHomeModalRef.current.open()}
        title={Localization.service.add_home_item_title}
        description={Localization.service.add_home_item_desc}
        itemMeta={{
          avatar: (
            <Text
              level={2}
              children={<PlusCircleOutlined className="item-icon" />}
            />
          ),
        }}
      />
      <Modal
        ref={addHomeModalRef}
        title={Localization.service.add_home_modal_title}
        child={() => <AddHomeLayout onSave={(data) => createProperty(data)} />}
      />
      <Modal
        ref={addInfoModalRef}
        child={renderAddInfoModal}
        title={Localization.general.additional_info}
      />
    </div>
  );
};

export default EditLocationDetails;
