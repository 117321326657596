import { Button, Form } from "../../../components";
import Localization from "../../../localization";
import React, { useRef } from "react";
import { GroupTypes } from "./groups";

const CreateGroups = ({ onSave = async () => {}, name = "" }) => {
  const formRef = useRef();

  return (
    <div>
      <Form
        ref={formRef}
        fields={[
          {
            value: name,
            name: ["data", "name"],
            rules: [{ required: true }],
            label: Localization.general.group_name,
            placeholder: Localization.general.firstLastName,
          },
          {
            face: "select",
            name: ["data", "type"],
            value: GroupTypes[1].id,
            rules: [{ required: true }],
            label: Localization.general.group_type,
            options: GroupTypes.map((_) => ({
              label: _.title,
              value: _.id,
            })),
          },
        ]}
      />
      <Button
        type="primary"
        className="w-100 m-20-t"
        title={Localization.general.save}
        onClick={async () => {
          try {
            await formRef.current.validateFields();
            await onSave(formRef.current.getFieldValue("data"));
          } catch (error) {}
        }}
      />
    </div>
  );
};

export default CreateGroups;
