import { CloseCircleOutlined } from "@ant-design/icons";
import { Button, Text } from "../../../components";
import Localization from "../../../localization";
import React, { useState } from "react";
import { Flex, Input, Tag } from "antd";
import { Tools } from "../../../utils";

const Invite = ({ onConfirm = () => {} }) => {
  const [emails, setEmails] = useState([]);
  const [text, setText] = useState("");

  const onChange = ({ key, target: { value } }) => {
    if (key === "Enter" || value.includes(" ")) {
      setEmails(
        [...emails, ...value.split(" ")].filter((_) => Tools.isEmail(_))
      );
      setText("");
    } else setText(value);
  };

  return (
    <div>
      <Text
        weight={"600"}
        children={Localization.service_edit_collaborators.invite_modal_title}
      />
      <Text
        children={Localization.service_edit_collaborators.invite_modal_desc}
      />
      <Text
        className="m-20-t m-5-b"
        children={
          Localization.service_edit_collaborators.invite_modal_input_label
        }
      />
      <Input.TextArea
        value={text}
        onChange={onChange}
        autoSize={{ minRows: 4, maxRows: 6 }}
        placeholder={
          Localization.service_edit_collaborators.invite_modal_input_placeholder
        }
        onKeyDown={(e) => {
          onChange(e);
          if (e.key === "Enter") e.preventDefault();
        }}
      />
      <Flex
        wrap="wrap"
        className="m-10-t"
        gap=".3em"
        children={emails.map((email, index) => (
          <Tag
            children={email}
            icon={
              <CloseCircleOutlined
                className="cursor-pointer"
                onClick={() => setEmails(emails.filter((_, i) => i !== index))}
              />
            }
          />
        ))}
      />
      <Button
        type="primary"
        className="m-30-t w-100"
        disabled={!emails.length}
        onClick={() => onConfirm(emails)}
        title={Localization.general.confirm}
      />
    </div>
  );
};

export default Invite;
